/* eslint-disable react/jsx-no-bind */
import { DragOutlined } from '@ant-design/icons';
import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useCallback, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import useSettingsBo from '../hooks/useSettingsBO';
import FlexGrow from './FlexGrow';

export function SortableItem({ id, children }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });
  const settings = useSettingsBo();

  return (
    <div
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        display: 'flex',
        gap: 8,
      }}
      {...attributes}
    >
      <div
        style={{
          cursor: 'grab',
          display: 'flex',
          alignItems: 'stretch',
          backgroundColor: settings?.primaryColor,
          borderRadius: '4px 0px 0px 4px',
          padding: 8,
        }}
        {...listeners}
      >
        <DragOutlined style={{ fontSize: 25, color: 'white' }} />
      </div>
      <div style={{ flexGrow: 1 }}>
        {children}
      </div>
    </div>
  );
}

export default function SortableItems2({ items, setItems, children }) {
  const [items2, setItems2] = useState([]);
  const sensors = useSensors(useSensor(PointerSensor));

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((prevItems) => {
        const oldIndex = items2.findIndex((f) => f.id === active.id);
        const newIndex = items2.findIndex((f) => f.id === over.id);

        setItems2(arrayMove(items2, oldIndex, newIndex));
        return arrayMove(prevItems, oldIndex, newIndex);
      });
    }
  }

  useEffect(() => {
    if (items && items2.length !== items.length) {
      setItems2(items.map(() => ({ id: v4() })));
    }
  }, [items]);

  useEffect(() => {
    if (items2.length < items?.length) {
      setItems2([...items2, { id: v4() }]);
    }
  }, [items2, items]);

  const onRemove = useCallback((i) => {
    setItems2([...items2.slice(0, i), ...items2.slice(i + 1)]);
    setItems((prevItems) => [...prevItems.slice(0, i), ...prevItems.slice(i + 1)]);
  }, [items2]);

  if (!items2.length || !items?.length) {
    return null;
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={items2}
        strategy={verticalListSortingStrategy}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8, marginBottom: 8 }}>
          {items2?.map((item, i) => (
            <SortableItem key={item.id} id={item.id}>
              {children({ i, onRemove })}
            </SortableItem>
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
}
