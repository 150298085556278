import { Button, Form, InputNumber, Table } from 'antd';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetParticipationQuery, useUpdateParticipationMutation } from '../../../../api/v2/participations';
import { useGetMyInvitationsQuery, useGetMyLinksQuery } from '../../../../api/v2/users';
import { useMyContext } from '../../../../hooks/useMyContext';
import BooleanIcon from '../../../../components/BooleanIcon';
import useNav from '../../../../hooks/useNav';

export default function UserLinks() {
  const { myContext, event } = useMyContext();
  const { userId } = useParams();
  const [form] = Form.useForm();
  const nav = useNav();

  const { data: participation } = useGetParticipationQuery({ ...myContext, userId });
  const { data: links, isFetching } = useGetMyLinksQuery({ ...myContext, userId });
  const [update, { isLoading }] = useUpdateParticipationMutation();

  useEffect(() => {
    if (participation?.invitationsQuota) {
      form.setFieldValue('invitationsQuota', participation?.invitationsQuota);
    }
  }, [participation]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <Form
        form={form}
        onFinish={(p) => update({ ...myContext, userId, participation: p })}
        style={{ display: 'flex', gap: 8 }}
      >
        <Form.Item name="invitationsQuota" label="Quota" style={{ marginBottom: 0 }}>
          <InputNumber />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>Enregistrer</Button>
        </Form.Item>
      </Form>
      <Table
        scroll={{ x: true }}
        loading={isFetching}
        dataSource={links}
        columns={[
          { title: 'Prénom', dataIndex: 'firstName', sorter: (a, b) => a.firstName?.localeCompare(b.firstName) },
          { title: 'Nom', dataIndex: 'lastName', sorter: (a, b) => a.lastName?.localeCompare(b.lastName) },
          { title: 'Email', dataIndex: 'email', sorter: (a, b) => a.email?.localeCompare(b.email) },
          { title: 'Mobile', dataIndex: 'mobile' },
          ...(event ? [
            {
              title: 'Invité',
              dataIndex: 'invited',
              render: (v) => <BooleanIcon value={v} />,
              sorter: (a, b) => b.invited - a.invited,
            },
            {
              title: 'Inscrit',
              dataIndex: 'isRegistered',
              render: (v) => <BooleanIcon value={v} />,
              sorter: (a, b) => b.isRegistered - a.isRegistered,
              defaultSortOrder: 'ascend',
            },
            {
              title: 'Présent',
              dataIndex: 'isPresent',
              render: (v) => <BooleanIcon value={v} />,
              sorter: (a, b) => b.isPresent - a.isPresent,
            },
          ] : []),
          {
            render: (v) => (
              <div style={{ display: 'flex', gap: 8 }}>
                <Button type="primary" onClick={(e) => nav(`/users/${v.id}`, { e })}>Voir son profil</Button>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
