import apiV1 from '.';

export const articlesApi = apiV1.injectEndpoints({
  endpoints: (builder) => ({
    getArticles: builder.query({
      query: ({ domainId, eventId }) => `/v2/domains/${domainId}/events/${eventId}/articles`,
      transformResponse: (response) => response.data,
      providesTags: ['Article'],
    }),
    updateArticle: builder.mutation({
      query: ({ domainId, eventId, article, articleId }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/articles/${articleId}`,
        method: 'PUT',
        body: article,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['Article'],
    }),
    removeArticle: builder.mutation({
      query: ({ domainId, eventId, articleId }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/articles/${articleId}`,
        method: 'DELETE',
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['Article'],
    }),
    createArticle: builder.mutation({
      query: ({ domainId, eventId, article }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/articles`,
        method: 'POST',
        body: article,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['Article'],
    }),
    removeArticleUser: builder.mutation({
      query: ({ domainId, eventId, articleId, dayId, userId }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/articles/${articleId}/days/${dayId}/users/${userId}`,
        method: 'DELETE',
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['PARTICIPATION', 'Article'],
    }),
    updateArticleUser: builder.mutation({
      query: ({ domainId, eventId, articleId, dayId, userId, body }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/articles/${articleId}/days/${dayId}/users/${userId}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['PARTICIPATION', 'Article'],
    }),
    addArticleUser: builder.mutation({
      query: ({ domainId, eventId, articleId, dayId, userId }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/articles/${articleId}/days/${dayId}/users/${userId}`,
        method: 'POST',
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['PARTICIPATION', 'Article'],
    }),
  }),
});

export const {
  useGetArticlesQuery,
  useCreateArticleMutation,
  useRemoveArticleMutation,
  useUpdateArticleMutation,
  useRemoveArticleUserMutation,
  useUpdateArticleUserMutation,
  useAddArticleUserMutation,
} = articlesApi;
