import queryString from 'query-string';
import apiV2 from '.';

export const formsApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    getParents: builder.query({
      query: ({ domainId, eventId }) => `/domains/${domainId}/forms/parents?${queryString.stringify({ eventId })}`,
      transformResponse: (response) => response.data?.parents || [],
      providesTags: ['FORMS'],
    }),
    getParentChilds: builder.query({
      query: ({ domainId, eventId, parentKey }) => `/domains/${domainId}/forms/parents/${parentKey}?${queryString.stringify({ eventId })}`,
      transformResponse: (response) => response.data?.childs || [],
      providesTags: ['FORMS'],
    }),
    getForms: builder.query({
      query: ({ domainId, eventId }) => (eventId ? `/domains/${domainId}/events/${eventId}/forms` : `/domains/${domainId}/forms`),
      transformResponse: (response) => response.data?.forms || [],
      providesTags: ['FORMS'],
    }),
    createForm: builder.mutation({
      query: ({ domainId, eventId, body, id }) => {
        let url = '';
        if (eventId) {
          if (id) {
            url = `/domains/${domainId}/events/${eventId}/forms/${id}`;
          } else {
            url = `/domains/${domainId}/events/${eventId}/forms`;
          }
        } else if (id) {
          url = `/domains/${domainId}/forms/${id}`;
        } else {
          url = `/domains/${domainId}/forms`;
        }

        return {
          url,
          method: id ? 'PUT' : 'POST',
          body,
        };
      },
      invalidatesTags: ['FORMS'],
    }),
    removeForm: builder.mutation({
      query: ({ domainId, id }) => ({
        url: `/domains/${domainId}/forms/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['FORMS'],
    }),
    updateData: builder.mutation({
      query: ({ domainId, eventId, userId, companyId, body }) => ({
        url: `/domains/${domainId}/forms/data?${queryString.stringify({ eventId, userId, companyId })}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['PARTICIPATION', 'USERS', 'COMPANIES'],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data.message,
    }),
    getData: builder.query({
      query: ({ domainId, eventId, userId, companyId }) => ({
        url: `/domains/${domainId}/forms/data?${queryString.stringify({ eventId, userId, companyId })}`,
      }),
      transformResponse: (response) => response.data,
      providesTags: ['PARTICIPATION', 'USERS', 'COMPANIES'],
    }),
  }),
});

export const {
  useGetParentsQuery,
  useGetParentChildsQuery,
  useLazyGetParentChildsQuery,
  useCreateFormMutation,
  useGetFormsQuery,
  useRemoveFormMutation,
  useUpdateDataMutation,
  useGetDataQuery,
} = formsApi;
