/* eslint-disable import/no-cycle */
/* eslint-disable no-lone-blocks */
import {
  AccountBookOutlined,
  ApartmentOutlined,
  BarcodeOutlined,
  ExclamationCircleOutlined,
  ImportOutlined,
  LineChartOutlined,
  ShopOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import React from 'react';
import OnlyEvent from './components/OnlyEvent';
import ArticlesPage from './pages/articles';
import BadgesPage from './pages/badges';
import CodesPage from './pages/codes';
import CompaniesPage from './pages/companies';
import CompanyPage from './pages/company';
import ConferencesPage from './pages/conferences';
import DomainPage from './pages/domain';
import DuplicatesPage from './pages/duplicates';
import EmailAccountsPage from './pages/emails/accounts';
import EmailTemplatesPage from './pages/emails/templates';
import Events from './pages/events';
import FiltersPage from './pages/filters';
import FormsPage from './pages/forms';
import GsmPage from './pages/gsm';
import ImportComexpo from './pages/import/comexpo';
import ImportCompanies from './pages/import/companies';
import ImportUsersV2 from './pages/import/users';
import InvoicesCreditsPage from './pages/invoices/credits';
import InvoicesGayakoaPage from './pages/invoices/gayakoa';
import InvoicesPage from './pages/invoices/others';
import LanguagesPage from './pages/languages';
import LivePage from './pages/live';
import ModelsPage from './pages/models/real';
import ModelVirtualsPage from './pages/models/virtual';
import ModerationsPage from './pages/moderations';
import PicturesPage from './pages/pictures';
import PointsPage from './pages/points';
import RightsPage from './pages/roles';
import ScanPage from './pages/scan';
import SectionsPage from './pages/sections';
import SellsPage from './pages/sells';
import SettingsPage from './pages/settings';
import SettingsExposium from './pages/settings/exposium';
import ShootingPage from './pages/shooting';
import Spots from './pages/spots';
import Stats from './pages/stats';
import TeamsPage from './pages/teams';
import ToolsPage from './pages/tools';
import Typos from './pages/typos';
import UserPage from './pages/user';
import Users from './pages/users';
import VouchersPage from './pages/vouchers';
import VoucherCreate from './pages/vouchers/v2/VoucherCreate';
import VoucherDetailsScreen from './pages/vouchers/v2/VoucherDetailsScreen';

export const routes = [
  { value: 'STATS', label: 'Statistiques', icon: <LineChartOutlined />, path: '/stats', component: <OnlyEvent><Stats /></OnlyEvent> },
  { value: 'USERS', label: 'Utilisateurs', icon: <TeamOutlined />, path: '/users/all', component: <Users /> },
  { value: 'COMPANIES', label: 'Sociétés', icon: <ApartmentOutlined />, path: '/companies', component: <CompaniesPage /> },
  {
    value: 'MODERATIONS',
    label: 'Modérations',
    icon: <ExclamationCircleOutlined />,
    path: '/moderations',
    component: <ModerationsPage />,
  },
  {
    value: 'DUPLICATIONS_USERS',
    label: 'Doublons',
    icon: <ExclamationCircleOutlined />,
    path: '/duplicates',
    component: <DuplicatesPage />,
  },
  {
    value: 'CONFERENCES',
    label: 'Conférences',
    icon: <ExclamationCircleOutlined />,
    path: '/conferences',
    component: <ConferencesPage />,
  },
  { value: 'SCAN', label: 'Émargement', icon: <BarcodeOutlined />, path: '/scan', component: <OnlyEvent><ScanPage /></OnlyEvent> },
  {
    value: 'IMPORT_USERS',
    label: 'Import users',
    label2: 'Import users (classique)',
    icon: <ImportOutlined />,
    path: '/import/users',
    component: <ImportUsersV2 />,
  },
  {
    value: 'IMPORT_USERS_COMEXPO',
    label: 'Import users',
    label2: 'Import users (comexpo)',
    icon: <ImportOutlined />,
    path: '/import/users/comexpo',
    component: <ImportComexpo />,
  },
  {
    value: 'IMPORT_COMPANIES',
    label: 'Import sociétés',
    icon: <ImportOutlined />,
    path: '/import/companies',
    component: <ImportCompanies />,
  },
  { value: 'SELL_V2', label: 'Ventes', icon: <ExclamationCircleOutlined />, path: '/sells', component: <SellsPage /> },
  {
    value: 'PROMO_CODES',
    label: 'Codes Promo',
    icon: <ExclamationCircleOutlined />,
    path: '/codes',
    component: <OnlyEvent><CodesPage /></OnlyEvent>,
  },
  { value: 'VOUCHERS_V2', label: 'Vouchers', icon: <ExclamationCircleOutlined />, path: '/vouchers', component: <VouchersPage /> },
  { value: 'LIVE', label: 'Live', icon: <ExclamationCircleOutlined />, path: '/live', component: <LivePage /> },
  { value: 'TEAMS', label: 'Équipes', icon: <ExclamationCircleOutlined />, path: '/teams', component: <TeamsPage /> },
  { value: 'BONUS_POINTS', label: 'Point Bonus', icon: <ExclamationCircleOutlined />, path: '/points', component: <PointsPage /> },
  { value: 'EVENTS', label: 'Événements', icon: <ShopOutlined />, path: '/events', component: <Events /> },
  {
    value: 'INVOICES_OTHERS',
    label: 'Factures',
    label2: 'Factures (Autres)',
    icon: <AccountBookOutlined />,
    path: '/invoices/others',
    component: <OnlyEvent><InvoicesPage /></OnlyEvent>,
  },
  {
    value: 'INVOICES_GAYAKOA',
    label: 'Factures',
    label2: 'Factures (Gayakoa)',
    icon: <AccountBookOutlined />,
    path: '/invoices',
    component: <OnlyEvent><InvoicesGayakoaPage /></OnlyEvent>,
  },
  {
    value: 'CREDITS',
    label: 'Avoirs',
    icon: <AccountBookOutlined />,
    path: '/credits',
    component: <OnlyEvent><InvoicesCreditsPage /></OnlyEvent>,
  },
  {
    label: 'Photos',
    icon: <ExclamationCircleOutlined />,
    path: '/photos',
    children: [
      { value: 'SESSIONS', label: 'Sessions', path: '/photos/sessions', component: <SectionsPage /> },
      { value: 'PICTURES', label: 'Photos', path: '/photos/pictures', component: <PicturesPage /> },
      { value: 'SHOOTING_FILTERS', label: 'Filtres', path: '/photos/filters', component: <FiltersPage /> },
      { value: 'SHOOTING', label: 'Shooting', path: '/photos/shooting', component: <ShootingPage /> },
    ],
  },
  {
    label: 'Réglages',
    icon: <ExclamationCircleOutlined />,
    path: '/settings',
    children: [
      { value: 'TYPO', label: 'Typologies', path: '/settings/typos', component: <OnlyEvent><Typos /></OnlyEvent> },
      { value: 'FORMS', label: 'Formulaires', path: '/settings/forms', component: <FormsPage /> },
      { value: 'BADGES', label: 'Badges', path: '/settings/badges', component: <BadgesPage /> },
      { value: 'EMAILS_ACCOUNTS', label: 'Compte Mailjet', path: '/settings/accounts', component: <EmailAccountsPage /> },
      {
        value: 'EMAILS_TEMPLATES',
        label: 'Template email',
        path: '/settings/templates',
        component: <OnlyEvent><EmailTemplatesPage /></OnlyEvent>,
      },
      {
        value: 'CUSTOMIZATIONS_FO',
        label: 'Config FO',
        path: '/settings/fo',
        component: <OnlyEvent><SettingsPage /></OnlyEvent>,
      },
      { value: 'CUSTOMIZATIONS_BO', label: 'Config BO', path: '/settings/bo', component: <DomainPage /> },
      // {
      //   value: 'CUSTOMIZATIONS_FO_MEETING',
      //   label: 'Config FO V2',
      //   label2: 'Config FO V2 (Meeting)',
      //   path: '/settings/fo/meeting',
      //   component: <OnlyEvent><SettingsMeetingPage /></OnlyEvent>,
      // },
      { value: 'SETTINGS_FO_EXPOSIUM', label: 'Config Exposium', path: '/settings/exposium', component: <SettingsExposium /> },
      { value: 'SECTIONS', label: 'Sections', path: '/settings/sections', component: <SectionsPage /> },
      { value: 'ARTICLES', label: 'Articles', path: '/settings/articles', component: <OnlyEvent><ArticlesPage /></OnlyEvent> },
      { value: 'TOOLS', label: 'Tools', path: '/settings/tools', component: <ToolsPage /> },
      { value: 'GSM', label: 'GSM', path: '/settings/gsm', component: <GsmPage /> },
      { value: 'AGENDA', label: 'Agenda', path: '/settings/spots', component: <OnlyEvent><Spots /></OnlyEvent> },
      { value: 'ROLES', label: 'Rôles', path: '/settings/roles', component: <RightsPage />, forAdmin: true },
      { value: 'MODELS_EXTENDS', label: 'Données étendues', path: '/settings/extends', component: <ModelsPage /> },
      { value: 'MODELS_VIRTUALS', label: 'Données virtuelles', path: '/settings/virtuals', component: <ModelVirtualsPage /> },
      { value: 'LANGUAGES', label: 'Langues', path: '/settings/languages', component: <LanguagesPage />, forAdmin: true },
    ],
  },
];

export const routesHidden = [
  { path: '/users/:userId', component: <UserPage /> },
  { path: '/companies/:companyId', component: <CompanyPage /> },
  { path: '/settings/fo/:fo/:tab', component: <OnlyEvent><SettingsPage /></OnlyEvent> },
  { path: '/vouchers/create', component: <OnlyEvent><VoucherCreate /></OnlyEvent> },
  { path: '/vouchers/:code', component: <OnlyEvent><VoucherDetailsScreen /></OnlyEvent> },
  { path: '/settings/fo/:fo', component: <OnlyEvent><SettingsPage /></OnlyEvent> },
];
