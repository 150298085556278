import React, { useState } from 'react';
import { Button, Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import IFrame from '../../components/IFrame';
import SettingsMeetingPage from './meeting';
import useNav from '../../hooks/useNav';
import FlexGrow from '../../components/FlexGrow';
import SettingsInformationsPage from './invitations';

const items = [
  {
    key: 'meeting',
    label: 'Meeting',
    children: <SettingsMeetingPage />,
  },
  {
    key: 'invitations',
    label: 'Invitations',
    children: <SettingsInformationsPage />,
  },
];

const VERSION_NAME = 'settings_version';

export default function SettingsPage() {
  const [version, setVersion] = useState(localStorage.getItem(VERSION_NAME) || '2');

  const nav = useNav();
  const { fo } = useParams();

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 8 }}>
        <Button
          type="link"
          onClick={() => {
            const newVersion = version === '1' ? '2' : '1';
            setVersion(newVersion);
            localStorage.setItem(VERSION_NAME, newVersion);
          }}
        >
          {version === '1' ? 'Revenir sur la nouvelle version' : "Utiliser l'ancienne version"}
        </Button>
      </div>

      {version === '1' && <FlexGrow><IFrame path="/settings/fo" /></FlexGrow>}
      {version === '2' && <Tabs activeKey={fo} onChange={(newTab) => nav(`/settings/fo/${newTab}`)} items={items} />}
      {/* {version === '2' && (fo === 'meeting' || !fo) && (
        <SettingsMeetingPage />
      )} */}
    </>
  );
}
