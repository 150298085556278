import apiV1 from '.';

export const eventsApi = apiV1.injectEndpoints({
  endpoints: (builder) => ({
    getEvent: builder.query({
      query: ({ domainId, eventId }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data,
      providesTags: ['EVENT'],
    }),
    getEvents: builder.query({
      query: ({ domainId, forExhibitor }) => `/domains/${domainId}/events?forExhibitor=${forExhibitor || false}`,
      transformResponse: (response) => response.data,
      providesTags: ['EVENT'],
    }),
    updateEvent: builder.mutation({
      query: ({ domainId, eventId, event }) => ({
        url: `/domains/${domainId}/events/${eventId}`,
        method: 'PUT',
        body: event,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['EVENT'],
    }),
    createEvent: builder.mutation({
      query: ({ domainId, event }) => ({
        url: `/domains/${domainId}/events`,
        method: 'POST',
        body: event,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['EVENT'],
    }),
    deleteEvent: builder.mutation({
      query: ({ domainId, eventId }) => ({
        url: `/domains/${domainId}/events/${eventId}`,
        method: 'DELETE',
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['EVENT'],
    }),
  }),
});

export const {
  useGetEventQuery,
  useGetEventsQuery,
  useUpdateEventMutation,
  useDeleteEventMutation,
  useCreateEventMutation,
} = eventsApi;
