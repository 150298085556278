import { Button, Form, Input, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useMyContext } from '../../../hooks/useMyContext';
import useNav from '../../../hooks/useNav';
import useSelectUser from './components/useSelectUser';
import { useCreateInvoiceV2Mutation, useGetLastInvoiceQuery } from '../../../api/v1/invoices';

function VoucherCreate() {
  const [form] = useForm();
  const { myContext } = useMyContext();
  const { search } = useLocation();
  const { user, SelectUser } = useSelectUser();
  const nav = useNav();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const userId = useMemo(() => user?.id || searchParams.get('userId'), [searchParams, user]);

  const [createInvoice, { isLoading: isCreating }] = useCreateInvoiceV2Mutation();
  const { data: invoice } = useGetLastInvoiceQuery({ ...myContext, userId: user?.id });

  useEffect(() => {
    if (invoice && form) {
      form.setFieldsValue(invoice);
    }
  }, [form, invoice]);

  return (
    <div>
      <Button style={{ marginBottom: 10 }} onClick={(e) => nav('/vouchers', { e })}>
        Retour
      </Button>
      {!userId && <Form.Item label="Propriétaire du voucher">{SelectUser}</Form.Item>}
      {userId && (
        <Form
          form={form}
          onFinish={async (values) => {
            console.log(user);
            if (user?.participation?.voucherCode) {
              createInvoice({
                ...myContext,
                body: { referer: window.location.href, bill: values.bill },
              });
              nav(`/vouchers/${user?.participation?.voucherCode}`);
            }
          }}
        >
          <Form.Item
            name={['bill', 'type']}
            rules={[{ required: true, message: 'Ce champ est obligatoire' }]}
          >
            <Select
              placeholder="Veuillez sélectionner"
              options={[
                { label: 'Société en France (société)', value: 1 },
                { label: 'Société en Europe (hors France)', value: 2 },
                { label: 'Société hors Europe', value: 3 },
                { label: 'Individuel (France ou étranger)', value: 4 },
                { label: 'Collectivité Territoriale', value: 5 },
                { label: 'Etablissement Public', value: 6 },
              ]}
            />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const types = getFieldValue(['bill', 'type']);

              return (
                [2, 3].includes(types) && (
                  <>
                    <Form.Item
                      name={['bill', 'intra_communautaire']}
                      label="N° TVA INTRA COMMUNAUTAIRE"
                      rules={[{ required: true, message: 'Ce champ est obligatoire' }]}
                      preserve={false}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name={['bill', 'country']}
                      label="Pays"
                      rules={[{ required: true, message: 'Ce champ est obligatoire' }]}
                      preserve={false}
                    >
                      <Input />
                    </Form.Item>
                  </>
                )
              );
            }}
          </Form.Item>
          <Form.Item
            label="Nom/Raison Sociale"
            name={['bill', 'social_reason']}
            rules={[{ required: true, message: 'Ce champ est obligatoire' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Adresse"
            name={['bill', 'address']}
            rules={[{ required: true, message: 'Ce champ est obligatoire' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Complément d'adresse" name={['bill', 'address_more']}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Code postal"
            name={['bill', 'postal_code']}
            rules={[{ required: true, message: 'Ce champ est obligatoire' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Ville"
            name={['bill', 'city']}
            rules={[{ required: true, message: 'Ce champ est obligatoire' }]}
          >
            <Input />
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={isCreating}>
            Créer
          </Button>
        </Form>
      )}
    </div>
  );
}

export default VoucherCreate;
