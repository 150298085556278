import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'antd';
import { useGetCompanyQuery, useUpdateCompanyMutation } from '../../api/v2/companies';
import { useMyContext } from '../../hooks/useMyContext';
import CompanyEmployees from './Employees';
import IFrame from '../../components/IFrame';
import FlexGrow from '../../components/FlexGrow';
import FormBuilder from '../../components/FormBuilder';
import ButtonConfirm from '../../components/ButtonConfirm';

function CompanyDetails() {
  const { myContext } = useMyContext();
  const { companyId } = useParams();

  const { data: company } = useGetCompanyQuery({ ...myContext, companyId });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <div style={{ fontSize: 18, fontWeight: '600' }}>{company.name}</div>
      {company.address1 && <div>{company.address1}</div>}
      {(company.postalCode || company.city)
              && <div>{`${company.postalCode && `${company.postalCode} `}${company.city && `${company.city}`}`}</div>}
      {company.phone && <div>{company.phone}</div>}
    </div>
  );
}

export default function CompanyPage() {
  const [version, setVersion] = useState(localStorage.getItem('user_version') || '2');

  const { myContext } = useMyContext();
  const { companyId } = useParams();

  const { data: company } = useGetCompanyQuery({ ...myContext, companyId });
  const [update, { isLoading: isUpdating }] = useUpdateCompanyMutation();

  if (!company) {
    return null;
  }

  return (
    <FlexGrow>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 8 }}>
        <Button
          type="link"
          onClick={() => {
            const newVersion = version === '1' ? '2' : '1';
            setVersion(newVersion);
            localStorage.setItem('company_version', newVersion);
          }}
        >
          {version === '1' ? 'Revenir sur la nouvelle version' : "Utiliser l'ancienne version"}
        </Button>
      </div>

      {company && version === '2' && !company.archive && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8, flexGrow: 1 }}>
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
              <CompanyDetails />
              <div style={{ display: 'flex', gap: 8 }}>
                <FormBuilder companyId={companyId} title="Modifier cette société" withModal>
                  <Button size="small" type="primary">Modifier</Button>
                </FormBuilder>
                <ButtonConfirm
                  title="Voulez-vous vraiment archiver cette société ?"
                  onOk={() => update({ ...myContext, companyId, company: { archive: true } })}
                >
                  <Button size="small" type="primary" loading={isUpdating} danger>Archiver</Button>
                </ButtonConfirm>
              </div>
            </div>
            <div />
          </div>
          <CompanyEmployees />
        </div>
      )}
      {company && version === '2' && company.archive ? (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <CompanyDetails />
          <div>
            <Button
              size="small"
              type="primary"
              onClick={() => update({ ...myContext, companyId, company: { archive: false } })}
              loading={isUpdating}
            >
              Réactiver cette société
            </Button>
          </div>
        </div>
      ) : null}

      {version === '1' && <IFrame path={`/companies/${company.id}`} />}

    </FlexGrow>
  );
}
