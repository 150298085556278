import apiV1 from '.';

export const companiesApi = apiV1.injectEndpoints({
  endpoints: (builder) => ({
    updateCompany: builder.mutation({
      query: ({ domainId, companyId, company }) => ({
        url: `/domains/${domainId}/companies/${companyId}`,
        method: 'PUT',
        body: company,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['Company'],
    }),
    getCompany: builder.query({
      query: ({ domainId, companyId }) => `/domains/${domainId}/companies/${companyId}`,
      transformResponse: (response) => response.data,
      providesTags: ['Company'],
    }),
    getMyCompanies: builder.query({
      query: ({ domainId, userId }) => ({
        url: `/v2/domains/${domainId}/companies/me?userId=${userId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data,
      providesTags: ['Company'],
    }),
  }),
});

export const { useUpdateCompanyMutation, useGetCompanyQuery, useGetMyCompaniesQuery } = companiesApi;
