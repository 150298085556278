import {
  Alert, Button, Form, Select, Tabs,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetFoSettingsQuery, useUpdateFoSettingsMutation } from '../../../api/v2/fo';
import { useGetLanguagesQuery } from '../../../api/v2/languages';
import { useMyContext } from '../../../hooks/useMyContext';
import MeetingEmails from './Emails';
import MeetingLanguages from './Languages';
import useNav from '../../../hooks/useNav';
import MeetingTypos from './Typos';
import MeetingLive from './Live';
import { FoContextProvider, useFoContext } from './context/useFoContext';
import MeetingFacturation from './Facturation';

const items = [
  {
    key: 'emails',
    label: 'Emails',
    children: <MeetingEmails />,
  },
  {
    key: 'languages',
    label: 'Multi Langues',
    children: <MeetingLanguages />,
  },
  {
    key: 'typos',
    label: 'Typologies',
    children: <MeetingTypos />,
  },
  {
    key: 'live',
    label: 'Live',
    children: <MeetingLive />,
  },
  {
    key: 'facturation',
    label: 'Facturation',
    children: <MeetingFacturation />,
  },
];

function SettingsMeeting() {
  const { myContext } = useMyContext();
  const { language, setLanguage } = useFoContext();
  const [form] = Form.useForm();
  const { tab } = useParams();
  const nav = useNav();

  const { data } = useGetFoSettingsQuery({ ...myContext, language });
  const [update, { isLoading, isSuccess, reset }] = useUpdateFoSettingsMutation();
  const { data: languages } = useGetLanguagesQuery();

  useEffect(() => {
    if (data) {
      form.resetFields();
      form.setFieldsValue(data);
    } else {
      form.resetFields();
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => reset(), 3000);
    }
  }, [isSuccess]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <Select options={languages?.map((m) => ({ label: m.label, value: m.key }))} value={language} onChange={setLanguage} />
      <Form form={form} onFinish={(values) => update({ ...myContext, extended: values, language })}>
        <Tabs activeKey={tab} onChange={(newTab) => nav(`/settings/fo/meeting/${newTab}`)} items={items} />
        {isSuccess && (
          <Form.Item>
            <Alert message="La configuration a été enregistrée avec succès" type="success" showIcon />
          </Form.Item>
        )}
        <Form.Item>
          <Button style={{ width: '100%' }} type="primary" loading={isLoading} htmlType="submit">Enregistrer</Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default function SettingsMeetingPage() {
  return (
    <FoContextProvider>
      <SettingsMeeting />
    </FoContextProvider>
  );
}
