import dayjs from 'dayjs';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getDate = (date) => capitalizeFirstLetter(dayjs(date).format('dddd DD MMMM'));

export const getTime = (date) => dayjs(date).format('HH:mm');

export const getDateTime = (date) => `${getDate(date)} à ${getTime(date)}`;

export const getDateTime2 = (dateA, dateB) => `${getDate(dateA)} de ${getTime(dateA)} à ${getTime(dateB)}`;
