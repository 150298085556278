import { Alert, Button, Checkbox, Divider, Form, Input, InputNumber, Modal, Radio, Select } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetDataQuery, useGetFormsQuery, useUpdateDataMutation } from '../api/v2/forms';
import { useMyContext } from '../hooks/useMyContext';
import useTranslations from '../hooks/useTranslations';

const capitalizeWords = (str) => str
  .toLowerCase()
  .replace(/\b\w/g, (char) => char.toUpperCase());

const toUpperCase = (str) => str.toUpperCase();

function Component(props) {
  const { model, value, onChange } = props;

  const { event } = useMyContext();

  const valuesArray = useMemo(() => {
    if (value) {
      return Object.keys(value);
    }
    return [];
  }, [value]);

  const valueString = useMemo(() => {
    if (value) {
      const arr = Object.keys(value);
      return arr.length ? arr[0] : null;
    }
    return null;
  }, [value]);

  const onChangeValuesArray = useCallback((e) => {
    const v = e?.target?.value || e;
    onChange(v.reduce((prev, curr) => ({ ...prev, [curr]: true }), {}));
  }, [onChange]);

  const onChangeValueString = useCallback((e) => {
    const v = e?.target?.value || e;
    onChange({ [v]: true });
  }, [onChange]);

  const disabled = useMemo(() => (model.model === 'UE' && !event?.id) || model.isDisabled, [model, event]);

  if (model.component === 'textarea') {
    return (
      <Input.TextArea
        {...props}
        rows={3}
        disabled={disabled}
      />
    );
  }

  if (model.component === 'input-number') {
    return (
      <InputNumber
        {...props}
        disabled={disabled}
      />
    );
  }

  if (model.component === 'input') {
    return (
      <Input
        {...props}
        disabled={disabled}
      />
    );
  }

  if (model.component === 'select') {
    return (
      <Select
        {...props}
        value={valueString}
        onChange={onChangeValueString}
        options={model.values}
        disabled={disabled}
      />
    );
  }

  if (model.component === 'select-multiple') {
    return (
      <Select
        mode="multiple"
        {...props}
        value={valuesArray}
        onChange={onChangeValuesArray}
        options={model.values}
        disabled={disabled}
      />
    );
  }

  if (model.component === 'radio') {
    return (
      <Radio.Group
        {...props}
        value={valueString}
        onChange={onChangeValueString}
        options={model.values}
        disabled={disabled}
      />
    );
  }

  if (model.component === 'checkbox') {
    return (
      <Checkbox.Group
        {...props}
        value={valuesArray}
        onChange={onChangeValuesArray}
        options={model.values}
        disabled={disabled}
      />
    );
  }

  return <div />;
}

function FormItem({ model }) {
  if (model.component === 'comment') {
    return (
      <Divider>{model.label}</Divider>
    );
  }

  return (
    <Form.Item
      label={model.label}
      name={model.isExtended ? [model.model, 'extended', model.key] : [model.model, model.key]}
      rules={[{ required: model.isRequired }]}
      normalize={(value) => {
        if (model.format === 'capitalize-words') {
          return capitalizeWords(value);
        }
        if (model.format === 'uppercase') {
          return toUpperCase(value);
        }
        return value;
      }}
    >
      <Component model={model} />
    </Form.Item>
  );
}

const defaultUserForm = {
  name: 'User',
  id: 184,
  type: 'U',
  layout: 'horizontal',
  models: [
    {
      value: 'Prénom',
      label: 'Prénom',
      key: 'firstName',
      isExtended: 0,
      model: 'U',
      component: 'input',
      isRequired: 1,
      modelId: 5317,
      order: 0,
      values: [],
    },
    {
      value: 'Nom',
      label: 'Nom',
      key: 'lastName',
      isExtended: 0,
      model: 'U',
      component: 'input',
      isRequired: 1,
      modelId: 5320,
      order: 1,
      values: [],
    },
    {
      value: 'Email',
      label: 'Email',
      key: 'email',
      isExtended: 0,
      model: 'U',
      component: 'input',
      isRequired: 0,
      modelId: 5321,
      order: 2,
      values: [],
    },
    {
      value: 'Mot de passe',
      label: 'Mot de passe',
      key: 'password',
      isExtended: 0,
      model: 'U',
      component: 'input',
      isRequired: 0,
      modelId: 5321,
      order: 3,
      values: [],
    },
  ],
};

const defaultCompanyForm = {
  type: 'C',
  layout: 'horizontal',
  models: [
    {
      value: 'Nom',
      label: 'Nom',
      key: 'name',
      isExtended: 0,
      model: 'C',
      component: 'input',
      isRequired: 1,
      modelId: 5317,
      order: 0,
      values: [],
    },
  ],
};

export default function FormBuilder({ children, userId, companyId, title, withModal, onFinish }) {
  const [open, setOpen] = useState(false);
  const [formId, setFormId] = useState();
  const [loading, setLoading] = useState(false);

  const { myContext } = useMyContext();
  const [form] = Form.useForm();
  const { t } = useTranslations();

  const { data: forms2 } = useGetFormsQuery({ ...myContext });
  const { data } = useGetDataQuery({ ...myContext, userId, companyId });
  const [update, { error }] = useUpdateDataMutation();

  const forms = useMemo(() => forms2?.filter((f) => (userId ? f.type === 'U' : f.type === 'C')), [forms2, userId, companyId]);

  const currentForm = useMemo(() => {
    if (forms) {
      const find = forms?.find((f) => f.id === formId);
      if (find) {
        return find;
      }
      if (userId) {
        return defaultUserForm;
      }
      return defaultCompanyForm;
    }
    return null;
  }, [forms, formId]);

  useEffect(() => {
    if (data && open) {
      form.resetFields();
      form.setFieldsValue(data);
    }
  }, [data, open]);

  useEffect(() => {
    if (forms?.length > 0) {
      setFormId(forms[0].id);
    }
  }, [forms]);

  const Builder = useCallback(() => (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      {forms?.length > 0 && (
        <Select
          placeholder="Choisir un formulaire"
          value={formId}
          onSelect={setFormId}
          options={forms?.map((m) => ({ label: m.name, value: m.id }))}
        />
      )}
      {currentForm && (
        <Form
          form={form}
          layout={currentForm.layout}
          onFinish={async (body) => {
            try {
              setLoading(true);
              const result = await update({ ...myContext, userId, companyId, body }).unwrap();
              await onFinish(result);
              setOpen(false);
            } catch (err) {
              console.log(err);
            } finally {
              setLoading(false);
            }
          }}
        >
          {currentForm.models.map((m) => <FormItem model={m} />)}
          {error && <Form.Item><Alert type="error" message={t(error)} showIcon /></Form.Item>}
          <div
            style={{
              position: 'sticky',
              display: 'flex',
              gap: 8,
              bottom: 0,
              backgroundColor: 'white',
              paddingTop: 8,
              zIndex: 9999,
            }}
          >
            <Button onClick={() => setOpen(false)}>Annuler</Button>
            <Button type="primary" htmlType="submit" style={{ flexGrow: 1 }} loading={loading}>Enregistrer</Button>
          </div>
        </Form>
      )}
    </div>
  ), [forms, formId, currentForm, myContext, userId, companyId, error, loading, form, onFinish]);

  if (!withModal) {
    return <Builder />;
  }

  return (
    <>
      {React.cloneElement(children, { onClick: () => setOpen(true) })}
      <Modal
        title={title || ' '}
        open={open}
        footer={null}
        onCancel={() => setOpen(false)}
        bodyStyle={{
          maxHeight: 'calc(100vh - 300px)',
          overflowY: 'auto',
        }}
      >
        <Builder />
      </Modal>
    </>
  );
}
