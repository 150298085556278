import React from 'react';
import { useParams } from 'react-router-dom';
import useDates from '../../../../hooks/useDates';
import { useMyContext } from '../../../../hooks/useMyContext';
import { useGetUserQuery, useUpdateUserMutation } from '../../../../api/v2/users';
import SearchUsers from '../../../../components/SearchUsers';

export default function UserModeration() {
  const { myContext } = useMyContext();
  const { userId } = useParams();
  const { dayHourMinute } = useDates();

  const { data: user } = useGetUserQuery({ ...myContext, userId });
  const [update] = useUpdateUserMutation();

  if (!user) {
    return null;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <div>
        {`Dernière modification le ${dayHourMinute(user.updatedAt)}`}
      </div>
      <div>
        {user.updatedBy.id && `Mise à jour par ${user.updatedBy.firstName || ''} ${user.updatedBy.lastName || ''}`}
      </div>
      <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
        <div>Géré par : </div>
        <SearchUsers
          defaultSearch={`${user.managedBy.firstName || ''} ${user.managedBy.lastName || ''}`}
          onSelect={(managedBy) => update({ ...myContext, userId, user: { managedBy } })}
        />
      </div>
    </div>
  );
}
