import apiV1 from '.';

export const emailsApi = apiV1.injectEndpoints({
  endpoints: (builder) => ({
    sendEmail2: builder.mutation({
      query: ({ domainId, eventId, templateId, accountId, usersId, variables }) => ({
        url: `/domains/${domainId}/events/${eventId}/emails/send`,
        method: 'POST',
        body: { templateId, accountId, usersId, variables },
      }),
      transformResponse: (response) => response.data,
    }),
    sendEmail: builder.mutation({
      query: ({ domainId, eventId, actionId, userIds, variables }) => {
        const url = eventId
          ? `/domains/${domainId}/events/${eventId}/emails`
          : `/domains/${domainId}/emails`;

        if (actionId && userIds) {
          return {
            url: `${url}/send`,
            method: 'POST',
            body: { actionId, usersId: userIds, variables },
          };
        }

        return null;
      },
      transformResponse: (response) => response.data,
    }),
  }),
});

export const { useSendEmailMutation, useSendEmail2Mutation } = emailsApi;
