import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetResumeQuery } from '../../../../api/v2/participations';
import { useMyContext } from '../../../../hooks/useMyContext';

export default function UserShop() {
  const { myContext } = useMyContext();
  const { userId } = useParams();

  const { data: resume } = useGetResumeQuery({ ...myContext, userId });

  if (!resume) {
    return null;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      {resume?.code && <div>{`Code utilisé : ${resume.code.code} (${resume.code.remise}% sur ${resume.code.label})`}</div>}
      <div>{`TOTAL HT : ${resume.totalHT} €`}</div>
      {resume.freeHT > 0 && <div>{`Articles gratuits : -${resume.freeHT} €`}</div>}
      {resume.remiseHT > 0 && <div>{`Code promo : -${resume.remiseHT} €`}</div>}
      {resume.prevHT > 0 && <div>{`Déjà payé : -${resume.prevHT} €`}</div>}
      <div />
      <div>{`TOTAL TVA : ${resume.totalTVA} €`}</div>
      <div>{`TOTAL TTC : ${resume.totalTTC} €`}</div>
    </div>
  );
}
