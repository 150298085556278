import { Tabs } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetFreeArticlesQuery } from '../../../api/v2/freeArticles';
import { useGetInvoicesQuery } from '../../../api/v2/invoices';
import { useMyContext } from '../../../hooks/useMyContext';
import useRights from '../../../hooks/useRights';
import useSettingsBo from '../../../hooks/useSettingsBO';
import UserEmployees from './Employees';
import UserFreeArticles from './freeArticles';
import UserInvoices from './invoices';
import UserInvoicesGayakoa from './invoicesGayakoa';
import { useGetCompanionsQuery } from '../../../api/v2/companions';
import UserCompanions from './companions';
import UserEmailLogs from './emailLogs';
import { useGetEmailLogsQuery } from '../../../api/v2/emails';
import UserCoworkers from './coworkers';
import { useGetLogsQuery } from '../../../api/v2/logs';
import UserLogs from './logs';
import { useGetCoworkersQuery } from '../../../api/v2/companies';
import { useGetEmployeesQuery } from '../../../api/v2/employees';
import UserParticipations from './participations';
import { useGetParticipationsQuery } from '../../../api/v2/participations';
import UserModeration from './moderation';
import UserShop from './shop';
import UserInvitations from './invitations';
import { useGetMyInvitationsQuery, useGetMyLinksQuery } from '../../../api/v2/users';
import UserLinks from './links';

export default function UserTabs() {
  const [active, setActive] = useState();

  const { myContext, event } = useMyContext();
  const { userId } = useParams();
  const { hasRight } = useRights();
  const settings = useSettingsBo();

  const { data: invoices } = useGetInvoicesQuery({ ...myContext, userId });
  const { data: freeArticles } = useGetFreeArticlesQuery({ ...myContext, userId });
  const { data: companions } = useGetCompanionsQuery({ ...myContext, userId });
  const { data: emailLogs } = useGetEmailLogsQuery({ ...myContext, userId });
  const { data } = useGetLogsQuery({ ...myContext, userId });
  const { data: coworkers } = useGetCoworkersQuery({ ...myContext, userId });
  const { data: employees } = useGetEmployeesQuery({ ...myContext, userId });
  const { data: participations } = useGetParticipationsQuery({ ...myContext, userId });
  const { data: invitations } = useGetMyInvitationsQuery({ ...myContext, userId });
  const { data: links } = useGetMyLinksQuery({ ...myContext, userId });

  const onChange = (v) => {
    setActive(v);
  };

  const items = useMemo(() => {
    const arr = [];

    if (hasRight('COMPANIES')) {
      arr.push({
        label: `Sociétés (${employees?.length || 0})`,
        key: 'companies',
        children: <UserEmployees />,
      });
      arr.push({
        label: `Collaborateurs (${coworkers?.length || 0})`,
        key: 'coworkers',
        children: <UserCoworkers />,
      });
    }

    if (event && settings?.facturationOthers) {
      arr.push(...[
        {
          label: `Factures (${invoices?.filter((f) => !f.isCredit).length || 0})`,
          key: 'invoices',
          children: <UserInvoices onTabs={onChange} />,
        },
        {
          label: `Avoirs (${invoices?.filter((f) => f.isCredit).length || 0})`,
          key: 'credits',
          children: <UserInvoices isCredit />,
        },
      ]);
    }

    if (event && settings?.facturationGayakoa) {
      arr.push({
        label: `Factures (${invoices?.filter((f) => !f.isCredit).length || 0})`,
        key: 'invoices-gayakoa',
        children: <UserInvoicesGayakoa />,
      });
    }

    if (event && settings?.hasFreeArticles) {
      arr.push({
        label: `Articles gratuits (${freeArticles?.length || 0})`,
        key: 'freeArticles',
        children: <UserFreeArticles />,
      });
    }

    if (event && settings?.companions) {
      arr.push({
        label: `Accompagnants (${companions?.length || 0})`,
        key: 'companions',
        children: <UserCompanions />,
      });
    }

    if (event && settings?.SHOW_USER_INVITATIONS) {
      arr.push({
        label: `Invitations (${invitations?.length || 0})`,
        key: 'invitations',
        children: <UserInvitations />,
      });
    }

    if (event && settings?.SHOW_USER_LINKS) {
      arr.push({
        label: `Relations (${links?.length || 0})`,
        key: 'links',
        children: <UserLinks />,
      });
    }

    if (settings?.SHOW_USER_MODERATION) {
      arr.push({
        label: 'Modération',
        key: 'moderation',
        children: <UserModeration />,
      });
    }

    if (settings?.SHOW_USER_SHOP) {
      arr.push({
        label: 'Shop',
        key: 'shop',
        children: <UserShop />,
      });
    }

    if (event) {
      arr.push({
        label: `Emails reçus (${emailLogs?.length || 0})`,
        key: 'email-logs',
        children: <UserEmailLogs />,
      });
      arr.push({
        label: `Logs (${data?.total || 0})`,
        key: 'logs',
        children: <UserLogs />,
      });
    }

    arr.push({
      label: `Participations (${participations?.length || 0})`,
      key: 'participations',
      children: <UserParticipations />,
    });

    return arr;
  }, [invoices, event, companions, freeArticles, data, emailLogs, coworkers, employees, invitations, links]);

  useEffect(() => {
    if (!active && items.length > 0) {
      setActive(items[0]?.key);
    }
  }, [items, active]);

  return items?.length > 0 && (
    <Tabs
      size="small"
      type="card"
      items={items}
      activeKey={active}
      onChange={onChange}
    />
  );
}
