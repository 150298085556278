import { Button } from 'antd';
import React, { useState } from 'react';
import FlexGrow from '../../components/FlexGrow';
import IFrame from '../../components/IFrame';
import { useMyContext } from '../../hooks/useMyContext';
import FormsV2 from './FormsV2';
import FormsV2Page from './v2';

const VERSION_NAME = 'forms';

export default function FormsPage() {
  const { myContext } = useMyContext();
  const [version, setVersion] = useState(myContext?.domainId === 192 ? localStorage.getItem(VERSION_NAME) || '2' : '2');

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 8 }}>
        <Button
          type="link"
          onClick={() => {
            const newVersion = version === '1' ? '2' : '1';
            setVersion(newVersion);
            localStorage.setItem(VERSION_NAME, newVersion);
          }}
        >
          {version === '1' ? 'Revenir sur la nouvelle version' : "Utiliser l'ancienne version"}
        </Button>
      </div>

      {version === '1' && <FlexGrow><IFrame path="/forms" /></FlexGrow>}
      {version === '2' && (
        <FormsV2Page />
      )}
    </>
  );
}
