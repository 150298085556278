import { Form, Switch } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetParticipationQuery, useUpdateParticipationMutation } from '../../../api/v2/participations';
import { useMyContext } from '../../../hooks/useMyContext';

export default function UserEventRemote() {
  const { myContext } = useMyContext();
  const { userId } = useParams();

  const [update, { isLoading }] = useUpdateParticipationMutation();
  const { data: participation } = useGetParticipationQuery({ ...myContext, userId });

  return (
    <Form.Item label="Distanciel" style={{ marginBottom: 0 }}>
      <Switch
        value={participation?.isRemote}
        onChange={(e) => update({ ...myContext, userId, participation: { isRemote: e } })}
        loading={isLoading}
      />
    </Form.Item>
  );
}
