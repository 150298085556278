import apiV1 from '.';

export const searchApi = apiV1.injectEndpoints({
  endpoints: (builder) => ({
    searchUser: builder.query({
      query: ({ domainId, eventId, q, onlyAdmin }) => (eventId
        ? `/domains/${domainId}/events/${eventId}/search?user=true&q=${q}&onlyAdmin=${onlyAdmin}`
        : `/domains/${domainId}/search?user=true&q=${q}&onlyAdmin=${onlyAdmin}`),
      transformResponse: (response) => response.data?.users,
    }),
  }),
});

export const { useLazySearchUserQuery } = searchApi;
