import { Button, Modal, Table } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCreateEmployeeMutation, useGetEmployeesQuery, useRemoveEmployeeMutation } from '../../../api/v2/employees';
import { useMyContext } from '../../../hooks/useMyContext';
import useNav from '../../../hooks/useNav';
import SearchUsers from '../../../components/SearchUsers';
import FormBuilder from '../../../components/FormBuilder';

function CreateEmployee() {
  const [open, setOpen] = useState(false);

  const { myContext } = useMyContext();
  const { userId } = useParams();

  const [createEmployee] = useCreateEmployeeMutation();

  return (
    <div>
      <Button type="primary" onClick={() => setOpen(true)}>Ajouter une société</Button>
      <Modal title="Ajouter une société" open={open} footer={null} onCancel={() => setOpen(false)}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <SearchUsers
            onSelect={async (companyId) => {
              if (companyId) {
                await createEmployee({ ...myContext, userId, companyId }).unwrap();
                setOpen(false);
              }
            }}
            onlyCompanies
          />
          <FormBuilder
            companyId={-1}
            title="Créer une société"
            onFinish={async (v) => {
              await createEmployee({ ...myContext, userId, companyId: v.id }).unwrap();
              setOpen(false);
            }}
            withModal
          >
            <Button type="primary">Créer une société</Button>
          </FormBuilder>
        </div>
      </Modal>
    </div>
  );
}

export default function UserEmployees() {
  const { myContext } = useMyContext();
  const { userId } = useParams();
  const nav = useNav();

  const { data } = useGetEmployeesQuery({ ...myContext, userId });
  const [removeEmployee, { isLoading: isRemoving }] = useRemoveEmployeeMutation();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <CreateEmployee />
      <Table
        pagination={false}
        dataSource={data}
        scroll={{ x: true }}
        columns={[
          { title: 'Nom', dataIndex: 'name' },
          { title: 'Index', dataIndex: 'siren' },
          { title: 'Ville', dataIndex: 'city' },
          { title: 'Code postal', dataIndex: 'postalCode' },
          { title: 'Adresse', dataIndex: 'address1' },
          { title: 'Collaborateurs', dataIndex: 'totalEmployees' },
          {
            render: (c) => (
              <div style={{ display: 'flex', gap: 8 }}>
                <Button type="primary" onClick={() => nav(`/companies/${c.id}`, { newTab: true })}>Voir</Button>
                <Button
                  type="primary"
                  onClick={() => removeEmployee({ ...myContext, companyId: c.id, userId })}
                  loading={isRemoving}
                  danger
                >
                  Retirer
                </Button>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
