import queryString from 'query-string';
import apiV1 from '.';

export const usersApi = apiV1.injectEndpoints({
  endpoints: (builder) => ({
    createTokenByUserId: builder.mutation({
      query: ({ domainId, userId }) => ({
        url: `/v2/domains/${domainId}/users/${userId}/tokens`,
        method: 'POST',
      }),
      transformResponse: (response) => response.data?.token,
    }),
    createUser: builder.mutation({
      query: ({ domainId, user }) => ({
        url: `/domains/${domainId}/users`,
        method: 'POST',
        body: user,
      }),
      transformResponse: (response) => response.data,
    }),
    updateUser: builder.mutation({
      query: ({ domainId, userId, user }) => ({
        url: `/domains/${domainId}/users/${userId}`,
        method: 'PUT',
        body: user,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['User', 'User_Duplications'],
    }),
    getUserEvent: builder.query({
      query: ({ domainId, eventId, code }) => `/domains/${domainId}/events/${eventId}/users${
        code ? `?${queryString.stringify({ code })}` : ''
      }`,
      transformResponse: (response) => response.data,
    }),
    getUser: builder.query({
      query: ({ domainId, eventId, userId }) => (eventId
        ? `/domains/${domainId}/events/${eventId}/users/${userId}`
        : `/domains/${domainId}/users/${userId}`),
      transformResponse: (response) => response.data,
      providesTags: ['User'],
    }),
    updateUserEvent: builder.mutation({
      query: ({ domainId, eventId, userId, userEvent }) => ({
        url: `/domains/${domainId}/events/${eventId}/users/${userId}`,
        method: 'PUT',
        body: userEvent,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['UserEvent', 'PARTICIPATION'],
    }),
    updateUserEventDays: builder.mutation({
      query: ({ domainId, eventId, dayId, userId, userEventDay, day }) => ({
        url: `/domains/${domainId}/events/${eventId}/days/${dayId}/users/${userId}`,
        method: 'PUT',
        body: userEventDay || day,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['UserEvent', 'PARTICIPATION'],
    }),
    removeEventDayUser: builder.mutation({
      query: ({ domainId, eventId, dayId, userId }) => ({
        url: `/domains/${domainId}/events/${eventId}/days/${dayId}/users/${userId}`,
        method: 'DELETE',
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['UserEvent', 'PARTICIPATION'],
    }),
    getMe: builder.query({
      query: ({ domainId }) => `/domains/${domainId}/users/me`,
      transformResponse: (response) => response.data,
    }),
    verifyCodes: builder.mutation({
      query: ({ domainId, codes }) => ({
        url: `/v2/domains/${domainId}/verify-codes`,
        method: 'POST',
        body: codes,
      }),
      transformResponse: (response) => response.data,
    }),
    removeUserEvent: builder.mutation({
      query: ({ domainId, eventId, userId }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/users/${userId}`,
        method: 'DELETE',
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['UserEvent', 'PARTICIPATION'],
    }),
    updateParticipationsDayByCodes: builder.mutation({
      query: ({ domainId, eventId, body }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/participations/days-by-codes`,
        method: 'PUT',
        body,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['UserEvent', 'PARTICIPATION'],
    }),
    getAllEmployees: builder.query({
      query: ({ domainId, userId }) => `/v2/domains/${domainId}/users/employees/all?userId=${userId}`,
      transformResponse: (response) => response.data,
      providesTags: ['User', 'EMPLOYEE'],
    }),
  }),
});

export const {
  useCreateTokenByUserIdMutation,
  useCreateUserMutation,
  useGetUserEventQuery,
  useLazyGetUserEventQuery,
  useUpdateUserEventMutation,
  useUpdateUserMutation,
  useGetMeQuery,
  useGetUserQuery,
  useUpdateUserEventDaysMutation,
  useVerifyCodesMutation,
  useRemoveUserEventMutation,
  useLazyGetUserQuery,
  useUpdateParticipationsDayByCodesMutation,
  useRemoveEventDayUserMutation,
  useGetAllEmployeesQuery,
} = usersApi;
