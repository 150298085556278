import apiV1 from '.';

export const vouchersApi = apiV1.injectEndpoints({
  endpoints: (builder) => ({
    getVouchersV2: builder.query({
      query: ({ domainId, eventId }) => `/v2/domains/${domainId}/events/${eventId}/vouchers/v2`,
      transformResponse: (response) => response?.data,
      providesTags: ['VOUCHERS', 'UserEvent'],
    }),
    getVoucherDetails: builder.query({
      query: ({ domainId, eventId, code }) => `/v2/domains/${domainId}/events/${eventId}/vouchers/v2/details/${code}`,
      transformResponse: (response) => response?.data,
      providesTags: ['VOUCHERS', 'UserEvent', 'Article'],
    }),
    deliverArticle: builder.mutation({
      query: ({ domainId, eventId, userId, articleId, dayId, voucherId }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/vouchers/v2/${voucherId}/deliver`,
        method: 'PUT',
        body: { userId, articleId, dayId },
      }),
      invalidatesTags: ['VOUCHERS', 'UserEvent'],
    }),
    commentDelivery: builder.mutation({
      query: ({ domainId, eventId, userDayId, comment }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/vouchers/v2/comment`,
        method: 'PUT',
        body: { userDayId, comment },
      }),
      invalidatesTags: ['VOUCHERS', 'UserEvent'],
    }),
    cancelDelivery: builder.mutation({
      query: ({ domainId, eventId, userDayId }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/vouchers/v2/cancel`,
        method: 'PUT',
        body: { userDayId },
      }),
      invalidatesTags: ['VOUCHERS', 'UserEvent'],
    }),
    getCommentDelivery: builder.query({
      query: ({ domainId, eventId, userDayId }) => `/v2/domains/${domainId}/events/${eventId}/vouchers/v2/comment?userDayId=${userDayId}`,
      transformResponse: (response) => response?.data,
      providesTags: ['VOUCHERS', 'UserEvent'],
    }),
    getVoucherByUserId: builder.query({
      query: ({ domainId, eventId, userId }) => `/v2/domains/${domainId}/events/${eventId}/vouchers/v2/me?userId=${userId}`,
      transformResponse: (response) => response?.data,
      providesTags: ['VOUCHERS', 'UserEvent'],
    }),
    getPromoCodes: builder.query({
      query: ({ domainId, eventId }) => `/v2/domains/${domainId}/events/${eventId}/vouchers/v2/codes`,
      transformResponse: (response) => response?.data,
      providesTags: ['VOUCHERS', 'UserEvent'],
    }),
    addPromoCode: builder.mutation({
      query: ({ domainId, eventId, promoCode }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/vouchers/v2/codes`,
        method: 'POST',
        body: promoCode,
      }),
      invalidatesTags: ['VOUCHERS', 'UserEvent'],
    }),
    removePromoCode: builder.mutation({
      query: ({ domainId, eventId, promoCode }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/vouchers/v2/codes`,
        method: 'DELETE',
        body: promoCode,
      }),
      invalidatesTags: ['VOUCHERS', 'UserEvent'],
    }),
    sendVouchers: builder.mutation({
      query: ({ domainId, eventId, body }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/vouchers/v2/send`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetVouchersV2Query,
  useGetVoucherDetailsQuery,
  useDeliverArticleMutation,
  useCommentDeliveryMutation,
  useGetCommentDeliveryQuery,
  useCancelDeliveryMutation,
  useGetVoucherByUserIdQuery,
  useGetPromoCodesQuery,
  useAddPromoCodeMutation,
  useSendVouchersMutation,
  useRemovePromoCodeMutation,
} = vouchersApi;
