import React from 'react';
import { useMyContext } from '../../../hooks/useMyContext';

function ResumeSmall({ hideTva, resume }) {
  const myContext = useMyContext();

  const { settings } = myContext;

  if (!resume) {
    return null;
  }

  return (
    <div style={{ fontSize: 12, textAlign: 'right' }}>
      <div style={{ fontWeight: 600 }}>
        <span>Total réservation : </span>
        <span>{`${(resume?.priceTotal || 0).toFixed(2)} € H.T.`}</span>
      </div>
      {resume?.priceTotalFree > 0 && (
        <div>
          <span>Remise engagement (qtés) : </span>
          <span>
            -
            {`${(resume.priceTotalFree || 0).toFixed(2)} € H.T.`}
          </span>
        </div>
      )}
      {resume?.remises > 0 && (
        <div>
          <span>Forfaits-journée gratuits (2/magasin) : </span>
          <span>
            -
            {`${resume.remises.toFixed(2)} € H.T.`}
          </span>
        </div>
      )}
      {resume?.freeArticlesCompaniesPrice > 0 && (
        <div>
          <span>Total articles gratuits : </span>
          <span>
            -
            {`${(resume.freeArticlesCompaniesPrice || 0).toFixed(2)} € H.T.`}
          </span>
        </div>
      )}
      {resume?.promoCode?.price > 0 && (
        <div>
          <span style={{ borderBottom: `2px solid ${settings?.FO_PRIMARY_COLOR}` }}>
            Code Promo :
            {' '}
          </span>
          <span style={{ borderBottom: `2px solid ${settings?.FO_PRIMARY_COLOR}` }}>
            -
            {' '}
            {`${(resume.promoCode.price || 0).toFixed(2)} € H.T.`}
          </span>
        </div>
      )}
      {resume?.priceTotalPaid > 0 && (
        <div>
          <span>Déjà payé : </span>
          <span>
            -
            {`${resume.priceTotalPaid.toFixed(2)} € H.T.`}
          </span>
        </div>
      )}
      <div
        style={{
          backgroundColor: resume?.isPaid ? 'green' : 'red',
          color: 'white',
          paddingRight: 5,
        }}
      >
        <div style={{ fontWeight: hideTva ? 600 : 400, marginTop: 20 }}>
          <span>Solde restant à payer : </span>
          <span>{`${resume.priceTotalToPay.toFixed(2)} € H.T.`}</span>
        </div>
        {!hideTva && (
          <>
            {resume?.withTva > 0 && (
              <div>
                <span>TVA : </span>
                <span>{`${(resume.tvaToPay || 0).toFixed(2)} €`}</span>
              </div>
            )}
            <div style={{ fontWeight: 600 }}>
              <span>Solde restant à payer : </span>
              <span>
                {`${(
                  (resume?.withTva ? resume?.priceTvaTotalToPay : resume?.priceTotalToPay) || 0
                ).toFixed(2)} € TTC`}
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ResumeSmall;
