import { Button, Form, Input, Modal, Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useCreateTemplateMutation, useGetAccountsQuery, useUpdateTemplateMutation } from '../../../api/v2/emails';
import { useMyContext } from '../../../hooks/useMyContext';
import { useGetLanguagesQuery } from '../../../api/v2/languages';
import { foEmails } from '../../settings/meeting/Emails';
import { useGetFoSettingsQuery, useUpdateFoSettingsMutation } from '../../../api/v2/fo';
import { boEmailsArr } from '../../../components/SendEmail';
import { useDomainsUpdateMutation } from '../../../api/v2/domains';
import useSettingsBo from '../../../hooks/useSettingsBO';

export default function TemplatesEdition({ children, template, onSave, languageId, style }) {
  const [visible, setVisible] = useState(false);

  const { myContext } = useMyContext();
  const [form] = Form.useForm();
  const settingsBo = useSettingsBo();

  const [createTemplate] = useCreateTemplateMutation();
  const [updateTemplate] = useUpdateTemplateMutation();
  const [updateFo] = useUpdateFoSettingsMutation();
  const [updateBo] = useDomainsUpdateMutation();
  const { data: accounts } = useGetAccountsQuery(myContext);
  const { data: languages } = useGetLanguagesQuery();
  const { data: settings } = useGetFoSettingsQuery({ ...myContext, languageId });

  const label = useMemo(() => (template ? 'Modifier' : 'Créer'), [template]);

  useEffect(() => {
    if (template?.id) {
      form.setFieldsValue({
        template,
        settings: foEmails.filter((f) => settings?.[f.value] === template.id).map((m) => m.value),
        bo: boEmailsArr.filter((f) => settingsBo?.[`${f.value}_${languageId}`] === template.id).map((m) => m.value),
      });
    }
  }, [form, template, settings, settingsBo, languageId]);

  useEffect(() => {
    if (languageId) {
      form.setFieldValue(['template', 'languageId'], languageId);
    }
  }, [languageId]);

  return (
    <div style={style}>
      {React.cloneElement(children, { onClick: () => setVisible(true) })}
      <Modal
        title={`${label} un template`}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          onFinish={async (values) => {
            let id = template?.id;
            if (template?.id) {
              await updateTemplate({ ...myContext, template: values.template, templateId: template.id }).unwrap();
            } else {
              id = await createTemplate({ ...myContext, template: values.template }).unwrap().then((d) => d.id);
              if (onSave) {
                await onSave(id);
              }
            }

            if (values.settings) {
              const s = foEmails
                .filter((f) => settings?.[f.value] === template.id)
                .reduce((prev, curr) => ({ ...prev, [curr.value]: null }), {});

              const ss = values.settings
                .reduce((prev, curr) => ({ ...prev, [curr]: id }), {});

              await updateFo({ ...myContext, languageId: values.template.languageId, extended: { ...s, ...ss } });
            }

            if (values.bo) {
              const ss = values.bo
                .reduce((prev, curr) => ({ ...prev, [`${curr}_${languageId}`]: id }), {});

              await updateBo({ ...myContext, domain: { extended: { ...ss } } });
            }

            setVisible(false);
            form.resetFields();
          }}
        >
          <Form.Item label="Label" name={['template', 'name']} rules={[{ required: true }]}>
            <Input disabled={template && !template.domainId} />
          </Form.Item>
          <Form.Item label="Template ID" name={['template', 'templateId']} rules={[{ required: true }]}>
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Account ID" name={['template', 'accountId']} rules={[{ required: true }]}>
            <Select options={accounts?.map((m) => ({ label: m.name, value: m.id }))} />
          </Form.Item>
          <Form.Item label="Langue" name={['template', 'languageId']} rules={[{ required: true }]} initialValue={1}>
            <Select options={languages?.map((m) => ({ label: m.label, value: m.id }))} disabled={languageId} />
          </Form.Item>
          <Form.Item label="FO" name="settings">
            <Select options={foEmails} mode="multiple" />
          </Form.Item>
          <Form.Item label="BO" name="bo">
            <Select options={boEmailsArr} mode="multiple" />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: 8 }}>
            <Button onClick={() => setVisible(false)}>
              Annuler
            </Button>
            <Button type="primary" htmlType="submit">
              {label}
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}
