import queryString from 'query-string';
import apiV2 from '.';

export const usersApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: ({ domainId, eventId, page, pageSize, filters, headers, orderBy }) => ({
        url: `/users?${queryString.stringify({
          page, pageSize, domainId, eventId,
        })}`,
        method: 'POST',
        body: { filters, headers, orderBy },
      }),
      transformResponse: (response) => response.data,
      providesTags: ['USERS', 'PARTICIPATION'],
    }),
    getMe: builder.query({
      query: ({ domainId }) => ({
        url: `/domains/${domainId}/users/me`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.me,
      providesTags: ['USER_PREF', 'USERS'],
    }),
    getUser: builder.query({
      query: ({ domainId, userId }) => ({
        url: `/domains/${domainId}/users/${userId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.user,
      providesTags: ['USERS'],
    }),
    getUserByCode: builder.query({
      query: ({ domainId, code }) => ({
        url: `/domains/${domainId}/users/by-code/${code}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.user,
      providesTags: ['USERS'],
    }),
    updateUserPref: builder.mutation({
      query: ({ domainId, prefs }) => ({
        url: `/domains/${domainId}/users/prefs`,
        method: 'PUT',
        body: { prefs },
      }),
      invalidatesTags: ['USER_PREF'],
    }),
    updateUser: builder.mutation({
      query: ({ domainId, userId, user }) => ({
        url: `/domains/${domainId}/users/${userId}`,
        method: 'PUT',
        body: { user },
      }),
      invalidatesTags: ['USERS'],
    }),
    createUsersExport: builder.query({
      query: ({ domainId, eventId, filters, headers }) => ({
        url: `/domains/${domainId}/users/export?${queryString.stringify({ domainId, eventId })}`,
        method: 'POST',
        body: { filters, headers },
      }),
      transformResponse: (response) => response.data.fileUrl,
      providesTags: ['USERS'],
    }),
    createUser: builder.mutation({
      query: ({ domainId, body }) => ({
        url: `/domains/${domainId}/users`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['USERS'],
    }),
    usersImportV2: builder.mutation({
      query: ({ domainId, eventId, users }) => ({
        url: `/domains/${domainId}/events/${eventId}/users/import/v2`,
        method: 'POST',
        body: { users },
      }),
      invalidatesTags: ['USERS'],
    }),
    getMyInvitations: builder.query({
      query: ({ domainId, eventId, userId }) => ({
        url: `/domains/${domainId}/events/${eventId}/users/${userId}/invitations`,
      }),
      transformResponse: (response) => response.data.users,
      providesTags: ['USERS', 'PARTICIPATION'],
    }),
    getMyLinks: builder.query({
      query: ({ domainId, eventId, userId }) => ({
        url: `/domains/${domainId}/events/${eventId}/users/${userId}/links`,
      }),
      transformResponse: (response) => response.data.users,
      providesTags: ['USERS', 'PARTICIPATION'],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetMeQuery,
  useUpdateUserPrefMutation,
  useLazyGetUsersQuery,
  useGetUserQuery,
  useLazyGetUserQuery,
  useLazyGetUserByCodeQuery,
  useCreateUserMutation,
  useUsersImportV2Mutation,
  useCreateUsersExportQuery,
  useUpdateUserMutation,
  useGetMyInvitationsQuery,
  useGetMyLinksQuery,
} = usersApi;
