import queryString from 'query-string';
import apiV1 from '.';

export const invoicesApi = apiV1.injectEndpoints({
  endpoints: (builder) => ({
    getInvoicesV2Stats: builder.query({
      query: ({ domainId, eventId }) => (eventId
        ? `/v2/domains/${domainId}/events/${eventId}/invoicesV2/stats`
        : `/v2/domains/${domainId}/invoicesV2/stats`),
      transformResponse: (response) => response.data,
    }),
    getInvoicesV2All: builder.query({
      query: ({ domainId, eventId, userId }) => (eventId
        ? `/v2/domains/${domainId}/events/${eventId}/invoicesV2/all?${queryString.stringify({
          userId,
        })}`
        : `/v2/domains/${domainId}/invoicesV2/all?${queryString.stringify({
          userId,
        })}`),
      transformResponse: (response) => response.data,
    }),
    getInvoicesV2Errors: builder.query({
      query: ({ domainId, eventId }) => (eventId
        ? `/v2/domains/${domainId}/events/${eventId}/invoicesV2/errors`
        : `/v2/domains/${domainId}/invoicesV2/errors`),
      transformResponse: (response) => response.data,
    }),
    downloadInvoicesV2: builder.mutation({
      query: ({ domainId, eventId, admin, invoiceId, year }) => ({
        url: eventId
          ? `/v2/domains/${domainId}/events/${eventId}/invoicesV2/download?${queryString.stringify({
            admin,
            invoiceId,
            year,
          })}`
          : `/v2/domains/${domainId}/invoicesV2/download?${queryString.stringify({
            admin,
            invoiceId,
            year,
          })}`,
        method: 'POST',
      }),
      transformResponse: (response) => response.data,
    }),
    getInvoicesResume: builder.query({
      query: ({ domainId, eventId, userId }) => `/v2/domains/${domainId}/events/${eventId}/invoicesV2/resume?${queryString.stringify({
        userId,
      })}`,
      transformResponse: (response) => response.data,
    }),
    createInvoiceV2: builder.mutation({
      query: ({ domainId, eventId, body }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/invoicesV2`,
        method: 'POST',
        body,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['Invoices'],
    }),
    getLastInvoice: builder.query({
      query: ({ domainId, eventId, userId }) => `/v2/domains/${domainId}/events/${eventId}/invoicesV2/last?userId=${userId}`,
      transformResponse: (response) => response.data?.invoice,
      providesTags: ['Invoices'],
    }),
    getMyInvoices: builder.query({
      query: ({ domainId, eventId }) => `/v2/domains/${domainId}/events/${eventId}/invoicesV2/me`,
      transformResponse: (response) => response.data?.invoices,
      providesTags: ['Invoices'],
    }),
    getMyPromoCode: builder.query({
      query: ({ domainId, eventId, userId }) => `/v2/domains/${domainId}/events/${eventId}/invoicesV2/promo-codes/me?userId=${userId}`,
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetInvoicesV2StatsQuery,
  useGetInvoicesV2AllQuery,
  useDownloadInvoicesV2Mutation,
  useGetInvoicesV2ErrorsQuery,
  useGetInvoicesResumeQuery,
  useCreateInvoiceV2Mutation,
  useGetLastInvoiceQuery,
  useGetMyInvoicesQuery,
  useGetMyPromoCodeQuery,
} = invoicesApi;
