import { Drawer } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import ParticipationsAddUsers from './ParticipationsAddUsers';
import ParticipationsArticles from './ParticipationsArticles';
import ParticipationsCreateUser from './ParticipationsCreateUser';
import { ParticipationsContext } from './useParticipations';
import { useMyContext } from '../../../../hooks/useMyContext';
import { useGetVoucherDetailsQuery } from '../../../../api/v1/vouchers';

function ParticipationsAdd({ children }) {
  const [step, setStep] = useState(0);
  const [visible, setVisible] = useState(false);
  const [userId, setUserId] = useState(null);

  const { myContext } = useMyContext();
  const { code } = useParams();

  const { data } = useGetVoucherDetailsQuery({ ...myContext, code });

  const onClose = useCallback(() => {
    setUserId(null);
    setStep(0);
    setVisible(false);
  }, []);

  const onShow = useCallback((uId) => {
    setVisible(true);
    if (uId) {
      setUserId(uId);
      setStep(2);
    }
  }, []);

  const value = useMemo(
    () => ({
      setStep,
      setUserId,
      userId,
      onClose,
      onShow,
      myUserId: data?.user?.id,
      myUser: data?.user,
    }),
    [data, onClose, onShow, userId],
  );

  return (
    <ParticipationsContext.Provider value={value}>
      {children}
      <Drawer
        title="Ajouter un testeur"
        placement="right"
        onClose={onClose}
        visible={visible}
        closable
      >
        {step === 0 && <ParticipationsAddUsers />}
        {step === 1 && <ParticipationsCreateUser />}
        {step === 2 && <ParticipationsArticles />}
      </Drawer>
    </ParticipationsContext.Provider>
  );
}

export default ParticipationsAdd;
