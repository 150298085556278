import { Button, Form, Input, InputNumber, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import SortableItems2 from '../../../components/SortableItems2';
import { useMyContext } from '../../../hooks/useMyContext';
import { useCreateModelMutation, useUpdateModelMutation } from '../../../api/v2/models';

function generateRandomString(length = 7) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

function ModelValue({ i, onRemove }) {
  return (
    <div style={{ display: 'flex', gap: 8, alignItems: 'flex-end' }}>
      <Form.Item label="Id" name={['values', i, 'id']} hidden>
        <Input />
      </Form.Item>
      <Form.Item label="Label" name={['values', i, 'value']} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Key" name={['values', i, 'key']} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Quota" name={['values', i, 'quota']}>
        <InputNumber />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          onClick={() => onRemove(i)}
          danger
        >
          Supprimer
        </Button>
      </Form.Item>
    </div>
  );
}

export default function ModelsRealEdition({ children, data }) {
  const [open, setOpen] = useState(false);

  const { myContext } = useMyContext();
  const [form] = Form.useForm();

  const [update] = useUpdateModelMutation();
  const [create] = useCreateModelMutation();

  useEffect(() => {
    if (!open) {
      if (data) {
        form.setFieldsValue({ model: data, values: data.values });
      } else {
        form.setFieldsValue({ model: { key: generateRandomString() }, values: [] });
      }
    }
  }, [data, open]);

  return (
    <>
      {React.cloneElement(children, { onClick: () => setOpen(true) })}
      <Modal
        title={data ? 'Modifier une donnée étendue' : 'Créer une donnée étendue'}
        width="90%"
        style={{ maxWidth: 1000 }}
        open={open}
        footer={null}
        onCancel={() => setOpen(false)}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={async (body) => {
            if (data) {
              update({ ...myContext, id: data.id, body });
            } else {
              create({ ...myContext, body });
            }
            setOpen(false);
            form.resetFields();
          }}
        >
          <Form.Item label="Label" name={['model', 'value']} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Type de donnée" name={['model', 'model']} rules={[{ required: true }]}>
            <Select options={[
              { label: 'Utilisateur', value: 'U' },
              { label: 'Utilisateur (événementiel)', value: 'UE' },
              { label: 'Société', value: 'C' },
            ]}
            />
          </Form.Item>
          <Form.Item label="Short label" name={['model', 'labelShort']}>
            <Input />
          </Form.Item>
          <Form.Item label="Key" name={['model', 'key']} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue, setFieldValue }) => {
              const values = getFieldValue('values');

              return (
                <SortableItems2
                  items={values}
                  setItems={(cb) => {
                    const newValues = cb(values);
                    setFieldValue('values', newValues);
                  }}
                >
                  {(props) => <ModelValue {...props} form={form} />}
                </SortableItems2>
              );
            }}
          </Form.Item>
          <Form.Item>
            <Button
              style={{ width: '100%' }}
              type="primary"
              onClick={() => {
                const v = form.getFieldsValue();
                const newV = { id: v4(), key: generateRandomString() };
                if (v.values) {
                  v.values.push(newV);
                } else {
                  v.values = [newV];
                }
                form.setFieldsValue(v);
              }}
              ghost
            >
              Ajouter une nouvelle valeur
            </Button>
          </Form.Item>

          <div style={{ display: 'flex', justifyContent: 'space-between', gap: 8 }}>
            <Button onClick={() => setOpen(false)}>Annuler</Button>
            <Button style={{ flexGrow: 1 }} type="primary" htmlType="submit" loading={false}>Enregistrer</Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
