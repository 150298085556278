import { useCallback, useMemo } from 'react';
import { useGetLanguagesQuery } from '../api/v2/languages';

export default function useTranslations() {
  const { data: languages2 } = useGetLanguagesQuery();

  const language = useMemo(() => languages2?.find((f) => f.key === 'FR'), [languages2]);

  const t = useCallback((key) => language?.translations[key] || key, [language]);

  return { t };
}
