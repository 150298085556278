import { Button, Form, Input, Modal, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useMyContext } from '../../../hooks/useMyContext';
import { useGetModelsQuery } from '../../../api/v2/models';
import { useCreateFormMutation } from '../../../api/v2/forms';
import SortableItems2 from '../../../components/SortableItems2';

const componentsMultiple = [
  {
    label: 'Sélecteur (unique)',
    value: 'select',
  },
  {
    label: 'Sélecteur (multiple)',
    value: 'select-multiple',
  },
  {
    label: 'Radio (unique)',
    value: 'radio',
  },
  {
    label: 'Checkbox (multiple)',
    value: 'checkbox',
  },
];

const components = [
  {
    label: 'Texte',
    value: 'input',
  },
  {
    label: 'Nombre',
    value: 'input-number',
  },
  {
    label: 'Texte sur plusieurs lignes',
    value: 'textarea',
  },
  {
    label: 'Commentaire',
    value: 'comment',
  },
];

const layouts = [
  {
    label: 'Horizontal',
    value: 'horizontal',
  },
  {
    label: 'Vertical',
    value: 'vertical',
  },
];

export const types = [
  {
    label: 'Utilisateur',
    value: 'U',
  },
  {
    label: 'Société',
    value: 'C',
  },
];

export const formats = [
  {
    label: 'Majuscule 1ère lettre',
    value: 'capitalize-words',
  },
  {
    label: 'Majuscule toutes les lettres',
    value: 'uppercase',
  },
];

function Item({ form, i, disabled, onRemove }) {
  const { myContext } = useMyContext();
  const { data: models } = useGetModelsQuery(myContext);

  const model = models?.find((f) => f.id === form.getFieldValue(['models', i, 'modelId']));
  const type = form.getFieldValue(['form', 'type']);

  const styles = { width: '20%', minWidth: 150 };

  return (
    <div style={{ display: 'flex', gap: 8, alignItems: 'flex-end', flexWrap: 'wrap' }}>
      <Form.Item label="Id" name={['models', i, 'id']} hidden>
        <Input />
      </Form.Item>
      <Form.Item
        label="Choisir une donnée étendue"
        name={['models', i, 'modelId']}
        style={{ ...styles, flexGrow: 1 }}
        rules={[{ required: true }]}
      >
        <Select
          onChange={() => {
            form.setFieldValue(['models', i, 'component'], null);
            form.setFieldValue(['models', i, 'format'], null);
          }}
          options={models
            ?.filter((f) => (type === 'U' ? ['U', 'UE']
              .includes(f.model) : f.model === type)).map((mo) => ({ label: mo.value, value: mo.id }))}
          popupMatchSelectWidth={false}
          filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
          showSearch
        />
      </Form.Item>
      <Form.Item
        label="Choisir un composant"
        style={styles}
        name={['models', i, 'component']}
        rules={[{ required: true }]}
      >
        <Select
          options={model?.values ? componentsMultiple : components}
          popupMatchSelectWidth={false}
        />
      </Form.Item>
      <Form.Item
        style={styles}
        label="Choisir un format"
        name={['models', i, 'format']}
      >
        <Select
          options={formats}
          popupMatchSelectWidth={false}
          disabled={model?.values}
          allowClear
        />
      </Form.Item>
      <Form.Item
        label="Non modifiable"
        name={['models', i, 'isDisabled']}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item
        label="Obligatoire"
        name={['models', i, 'isRequired']}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          onClick={() => onRemove(i)}
          danger
          disabled={disabled}
        >
          Supprimer
        </Button>
      </Form.Item>
    </div>
  );
}

export default function FormsEdition({ children, values }) {
  const [open, setOpen] = useState(false);

  const { myContext } = useMyContext();
  const [form] = Form.useForm();

  const [create, { isLoading }] = useCreateFormMutation();

  useEffect(() => {
    if (!open) {
      if (values) {
        form.setFieldsValue({ form: values, models: values.models });
      } else {
        form.setFieldsValue({ form: { }, models: [{}] });
      }
    }
  }, [values, open]);

  return (
    <>
      {React.cloneElement(children, { onClick: () => setOpen(true) })}
      <Modal width="90%" style={{ maxWidth: 1200 }} open={open} footer={null} onCancel={() => setOpen(false)}>
        <Form
          layout="vertical"
          form={form}
          onFinish={async (body) => {
            await create({ ...myContext, body, id: values?.id }).unwrap();
            setOpen(false);
          }}
        >
          <Form.Item label="Nom" name={['form', 'name']} rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Type de données" name={['form', 'type']} rules={[{ required: true }]} initialValue="U">
            <Select options={types} onChange={() => form.setFieldValue(['models'], [{}])} />
          </Form.Item>
          <Form.Item label="Disposition" name={['form', 'layout']} rules={[{ required: true }]} initialValue="vertical">
            <Select options={layouts} />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue, setFieldValue }) => {
              const formModels = getFieldValue('models');

              return (
                <SortableItems2
                  items={formModels}
                  setItems={(cb) => {
                    const newValues = cb(formModels);
                    setFieldValue('models', newValues);
                  }}
                >
                  {(props) => <Item {...props} form={form} disabled={formModels?.length <= 1} />}
                </SortableItems2>
              );
            }}
          </Form.Item>
          <Form.Item>
            <Button
              style={{ width: '100%' }}
              type="primary"
              onClick={() => {
                const v = form.getFieldsValue();
                console.log(v);
                v.models.push({});
                form.setFieldsValue(v);
              }}
              ghost
            >
              Ajouter une donnée étendue
            </Button>
          </Form.Item>

          <div style={{ display: 'flex', justifyContent: 'space-between', gap: 8 }}>
            <Button onClick={() => setOpen(false)}>Annuler</Button>
            <Button style={{ flexGrow: 1 }} type="primary" htmlType="submit" loading={isLoading}>Enregistrer</Button>
          </div>
        </Form>

      </Modal>
    </>
  );
}
