import { Alert, Button, Form, Modal, Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSendEmailMutation } from '../api/v2/emails';
import { useGetLanguagesQuery } from '../api/v2/languages';
import { useGetUserQuery } from '../api/v2/users';
import { useMyContext } from '../hooks/useMyContext';
import useSettingsBo from '../hooks/useSettingsBO';

export const boEmails = {
  EMAILS_SEND_INVITATION: {
    label: 'Envoyer une invitation',
    value: 'EMAILS_SEND_INVITATION',
  },
  EMAILS_SEND_PARTICIPATION: {
    label: 'Envoyer sa participation',
    value: 'EMAILS_SEND_PARTICIPATION',
  },
  EMAILS_SEND_CONFERENCES: {
    label: 'Envoyer ses conférences',
    value: 'EMAILS_SEND_CONFERENCES',
  },
  EMAILS_SEND_WELCOME: {
    label: 'Envoyer un email de bienvenue',
    value: 'EMAILS_SEND_WELCOME',
  },
  EMAILS_SEND_BADGE: {
    label: 'Envoyer le badge',
    value: 'EMAILS_SEND_BADGE',
  },
};

export const boEmailsArr = Object.values(boEmails);

export default function SendEmail({ email }) {
  const [open, setOpen] = useState(false);
  const [languageId, setLanguageId] = useState();

  const { myContext } = useMyContext();
  const { userId } = useParams();
  const [form] = Form.useForm();

  //   const settings = useSettingsFo();

  const settings = useSettingsBo();

  const { data: user } = useGetUserQuery({ ...myContext, userId });
  const [send, { isLoading }] = useSendEmailMutation();
  const { data: languages } = useGetLanguagesQuery();

  const templateId = useMemo(() => (settings && email ? settings[`${email.value}_${languageId}`] : null), [settings, email, languageId]);

  useEffect(() => {
    if (languages) {
      setLanguageId(languages[0]?.id);
    }
  }, [languages]);

  return (
    <div>
      <Button type="primary" onClick={() => setOpen(true)}>{email.label}</Button>
      <Modal title={email.label} open={open} onCancel={() => setOpen(false)} width={600} footer={null}>
        <Form
          form={form}
          onFinish={async () => {
            await send({ ...myContext, userId, templateId }).unwrap();
            setOpen(false);
          }}
        >
          <Form.Item label="Langue utilisée dans l'email">
            <Select
              options={languages?.map((m) => ({ ...m, key: m.id }))}
              value={languageId}
              onChange={(v) => {
                setLanguageId(v);
                form.resetFields();
              }}
            />
          </Form.Item>

          {!templateId && (
            <Form.Item>
              <Alert
                message="Pas de gabarit de message disponible, veuillez configurer un gabarit dans le menu gabarit e-mails."
                type="error"
                showIcon
              />
            </Form.Item>
          )}

          <Button
            style={{ width: '100%' }}
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={!templateId}
          >
            Envoyer
          </Button>
        </Form>
      </Modal>
    </div>
  );
}
