import { Button, Table } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetEmployeesByCompanyQuery } from '../../api/v2/employees';
import { useMyContext } from '../../hooks/useMyContext';
import useNav from '../../hooks/useNav';
import BooleanIcon from '../../components/BooleanIcon';

export default function CompanyEmployees() {
  const { myContext, event } = useMyContext();
  const { companyId } = useParams();
  const nav = useNav();

  const { data, isFetching } = useGetEmployeesByCompanyQuery({ ...myContext, companyId });

  return (
    <div>
      <Table
        scroll={{ x: true }}
        loading={isFetching}
        pagination={false}
        dataSource={data}
        columns={[
          { title: 'Prénom', dataIndex: 'firstName', sorter: (a, b) => a.firstName?.localeCompare(b.firstName) },
          { title: 'Nom', dataIndex: 'lastName', sorter: (a, b) => a.lastName?.localeCompare(b.lastName) },
          { title: 'Email', dataIndex: 'email', sorter: (a, b) => a.email?.localeCompare(b.email) },
          { title: 'Mobile', dataIndex: 'mobile' },
          { title: 'Fixe', dataIndex: 'phone' },
          ...(event ? [
            {
              title: 'Invité',
              dataIndex: 'invited',
              render: (v) => <BooleanIcon value={v} />,
              sorter: (a, b) => b.invited - a.invited,
            },
            {
              title: 'Inscrit',
              dataIndex: 'isRegistered',
              render: (v) => <BooleanIcon value={v} />,
              sorter: (a, b) => b.isRegistered - a.isRegistered,
              defaultSortOrder: 'ascend',
            },
            {
              title: 'Présent',
              dataIndex: 'isPresent',
              render: (v) => <BooleanIcon value={v} />,
              sorter: (a, b) => b.isPresent - a.isPresent,
            },
          ] : []),
          {
            render: (c) => (
              <div style={{ display: 'flex', gap: 8 }}>
                <Button type="primary" onClick={() => nav(`/users/${c.id}`, { newTab: true })}>Voir</Button>
                <Button type="primary" danger>Retirer</Button>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
