import { Button } from 'antd';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useCreateTokenQuery } from '../../../api/v2/authentications';
import { useMyContext } from '../../../hooks/useMyContext';
import useRights from '../../../hooks/useRights';
import useSettingsBo from '../../../hooks/useSettingsBO';
import useEnv from '../../../hooks/useEnv';

const isLocal = window.location.hostname === 'localhost';

export default function UserToken() {
  const { userId } = useParams();
  const { myContext, event } = useMyContext();
  const { hasRight } = useRights();
  const settings = useSettingsBo();
  const env = useEnv();

  const { data: token, isFetching } = useCreateTokenQuery({ ...myContext, userId });

  console.log(token);

  const options = useMemo(() => {
    const arr = [];
    if (hasRight('SETTINGS_FO_EXPOSIUM')) {
      const baseUrl = isLocal ? 'http://localhost:3000' : 'https://invoice.sialparis.fr';
      const url = `${baseUrl}?token=${token}&domainId=${myContext?.domainId}&eventId=${myContext?.eventId}`;
      arr.push({ label: 'Se connecter', value: url });
    }
    if (event?.alias) {
      let baseUrl = 'https://meeting.gayakoa.com';
      if (isLocal) {
        baseUrl = 'http://localhost:4001';
      } else if (env === 'dev') {
        baseUrl = 'https://d1cwsl2gmmbvf6.cloudfront.net';
      }
      const url = `${baseUrl}/${event?.alias}?token=${token}`;
      arr.push({ label: 'Se connecter', value: url });
    }
    if (settings?.foPay) {
      const baseUrl = isLocal ? 'http://localhost:3000' : 'https://event.skiforcepro.fr';
      const url = `${baseUrl}/domains/${myContext?.domainId}/events/${myContext?.eventId}?token=${token}`;
      arr.push({ label: 'Se connecter (PAY)', value: url });
    }
    return arr;
  }, [myContext, event, hasRight, settings, token]);

  if (!event || options.length === 0) {
    return null;
  }

  return (
    <>
      {options.map((m) => (
        <Button
          style={{ flexGrow: 1 }}
          size="small"
          type="primary"
          href={m.value}
          target="_blank"
          loading={isFetching}
        >
          {m.label}
        </Button>
      ))}
    </>
  );
}
