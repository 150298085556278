import { CheckCircleOutlined } from '@ant-design/icons';
import { Select, Spin } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { useMyContext } from '../../../../hooks/useMyContext';
import { useLazySearchUserQuery } from '../../../../api/v1/search';

const useSelectUser = ({ hideIds, onlyAdmin, placeholder } = { hideIds: [], defaultUserId: null }) => {
  const { myContext } = useMyContext();
  const [getUsers, { data: users, isLoading }] = useLazySearchUserQuery();

  const [promise, setPromise] = useState(null);
  const [selected, setSelected] = useState();

  const onChange = useCallback(
    (userId) => {
      const find = users.find((u) => u.id === userId);
      if (!find) {
        setSelected(null);
      } else {
        setSelected(find);
      }
    },
    [users],
  );

  const SelectUser = useMemo(
    () => (
      <Select
        key="select"
        value={selected?.id}
        style={{ minWidth: 250, width: '100%' }}
        filterOption={false}
        onSearch={(value) => {
          if (promise) promise.abort();
          if (value) {
            setPromise(getUsers({ ...myContext, q: value, onlyAdmin }));
          }
        }}
        onChange={onChange}
        notFoundContent={isLoading ? <Spin size="small" /> : null}
        options={(users || [])
          .filter((user) => (hideIds ? !hideIds.find((id) => id === user.id) : true))
          .map((user) => ({
            label: (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  {`${user?.firstName || ''} ${user?.lastName || ''} ${
                    user?.Authentication ? `(${user.Authentication.email})` : ''
                  }`}
                </div>
                {user?.participation?.days.length > 0 && (
                  <CheckCircleOutlined style={{ color: 'green', fontSize: 18, marginLeft: 4 }} />
                )}
              </div>
            ),
            value: user.id,
          }))}
        placeholder={placeholder || 'Chercher un utilisateur'}
        showSearch
        allowClear
      />
    ),
    [getUsers, hideIds, isLoading, myContext, onChange, onlyAdmin, promise, selected, users],
  );

  return { SelectUser, user: selected, setUser: setSelected };
};

export default useSelectUser;
