import React, { useEffect, useRef, useState } from 'react';
import { useLayout } from '../hooks/useLayout';

export default function BottomBar({ children }) {
  const [height, setHeight] = useState(0);

  const { isMobile, sidebarWidth } = useLayout();
  const divRef = useRef(null);

  useEffect(() => {
    if (divRef.current) {
      setHeight(divRef.current.offsetHeight);
    }

    const handleResize = () => {
      if (divRef.current) {
        setHeight(divRef.current.offsetHeight);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{ height: height - 8 }}>
      <div
        ref={divRef}
        style={{
          background: 'white',
          position: 'fixed',
          bottom: 0,
          left: isMobile ? 0 : sidebarWidth,
          right: 0,
          padding: 16,
          transition: 'all 0.3s',
          boxShadow: '1px 0 8px rgba(0, 0, 0, 0.6)',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {React.cloneElement(children, { style: { width: '100%', maxWidth: 500 } })}
      </div>
    </div>
  );
}
