import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Space } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import QRCode from 'qrcode';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRemoveArticleUserMutation, useUpdateArticleUserMutation } from '../../../api/v1/articles.api';
import { useSendEmail2Mutation } from '../../../api/v1/emails';
import { useGetEventQuery } from '../../../api/v1/events';
import { useCreateTokenByUserIdMutation, useUpdateUserEventDaysMutation, useUpdateUserEventMutation } from '../../../api/v1/users';
import { useGetVoucherDetailsQuery } from '../../../api/v1/vouchers';
import QReader from '../../../components/QReader';
import { useMyContext } from '../../../hooks/useMyContext';
import useNav from '../../../hooks/useNav';
import { getDate } from './components/date';
import ParticipationsAdd from './participations/ParticipationsAdd';
import { useParticipations } from './participations/useParticipations';
import ResumeSmall from './resumeSmall';
import useMe from '../../../hooks/useMe';

const { confirm } = Modal;

function capitalizeFirstLetter(string) {
  if (!string) {
    return null;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function ParticipationsList({
  setScanUserId,
  selectUserId,
  setSelectUserId,
  setCommentDeliveryId,
}) {
  const [sortBy, setSortBy] = useState('name');
  const [qrCode, setQrCode] = useState();

  const { myContext, settings } = useMyContext();
  const me = useMe();
  const nav = useNav();
  const { code } = useParams();
  const { onShow } = useParticipations();

  const { data, isLoading } = useGetVoucherDetailsQuery({ ...myContext, code });
  const [removeArticle, { isLoading: isRemovingArticle }] = useRemoveArticleUserMutation();
  const [updateArticle, { isLoading: isUpdatingArticle }] = useUpdateArticleUserMutation();
  const [createToken, { isLoading: isCreatingToken, data: token }] = useCreateTokenByUserIdMutation();
  const { data: event } = useGetEventQuery(myContext);
  const [updateUserEventDays, { isLoading: isEmarging }] = useUpdateUserEventDaysMutation();
  const [send] = useSendEmail2Mutation();

  const currentDay = event?.days.find((ed) => moment(ed.day).isSame(moment(), 'day'));

  const getToken = useCallback(async () => {
    if (data?.user) {
      const t = await createToken({ ...myContext, userId: data.user.id }).unwrap();
      QRCode.toDataURL(`${settings?.WEBSITE_URL}/register?token=${t}`).then(setQrCode);
    }
  }, [createToken, data, myContext, settings]);

  useEffect(() => {
    getToken();
  }, [getToken]);

  const usersSort = useMemo(() => {
    if (!data?.users) {
      return [];
    }
    const { users } = data;
    if (sortBy === 'name') {
      return [...users].sort((a, b) => `${a.lastName}`.localeCompare(b.lastName));
    }
    if (sortBy === 'delivery') {
      return [...users].sort((a, b) => {
        const ad = !!a.userArticles.find((f) => !f.isDelivered);
        const bd = !!b.userArticles.find((f) => !f.isDelivered);
        return bd - ad;
      });
    }
    return users;
  }, [data, sortBy]);

  if (isLoading) {
    return <div>Chargement...</div>;
  }

  if (!data) {
    return null;
  }

  const { user, resume, users } = data;

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: 350, marginRight: 10, backgroundColor: '#F0F0F0' }}>
        <div
          style={{
            textAlign: 'center',
            backgroundColor: '#4F4F4F',
            padding: 10,
            color: 'white',
            fontWeight: 600,
            fontSize: 20,
          }}
        >
          Voucher
        </div>
        <div style={{ padding: 10, textAlign: 'center' }}>
          <div style={{ fontWeight: 600 }}>{`${user.firstName || ''} ${user.lastName || ''}`}</div>
          <div>{`(${user.auth?.email || ''})`}</div>
          <Button onClick={() => nav(`/users/${user.id}`, { newTab: true })}>Open</Button>
          {/* <div>{`Le ${dayjs(voucher.createdAt).format("DD/MM/YYYY à hh:mm")}`}</div> */}
        </div>
        <div style={{ padding: 10 }}>
          <ResumeSmall resume={resume} />
        </div>
        <Space style={{ padding: 10 }}>
          <Button
            type="primary"
            onClick={async () => {
              await send({
                ...myContext,
                templateId: settings?.FO_PAY_SEND_PAIEMENT_TEMPLATE_ID,
                accountId: settings?.FO_PAY_SEND_PAIEMENT_ACCOUNT_ID,
                usersId: [user.id],
                variables: { url: `${settings?.WEBSITE_URL}/register?token=${token}` },
              });
            }}
            loading={isCreatingToken}
            disabled={!token}
          >
            Lien de paiement
          </Button>
          <Button
            onClick={() => window.open(`${settings?.WEBSITE_URL}/register?token=${token}`, '_blank')}
          >
            Open
          </Button>
        </Space>
        <div>
          <img style={{ width: '100%' }} src={qrCode} alt="" />
        </div>
      </div>
      <div style={{ flexGrow: 1 }}>
        <div
          style={{
            textAlign: 'center',
            backgroundColor: '#4F4F4F',
            padding: 10,
            color: 'white',
            fontWeight: 600,
            fontSize: 20,
            marginBottom: 10,
          }}
        >
          {`Participants (${users.length})`}
        </div>
        <div>
          <Space style={{ marginBottom: 10 }}>
            <Button onClick={(e) => nav('/vouchers', { e })}>Retour</Button>
            <Button type="primary" onClick={() => onShow()}>
              Ajouter un participant
            </Button>
            <Button
              type={sortBy === 'name' ? 'primary' : 'default'}
              onClick={() => setSortBy('name')}
            >
              Tri par le nom
            </Button>
            <Button
              type={sortBy === 'delivery' ? 'primary' : 'default'}
              onClick={() => setSortBy('delivery')}
            >
              Tri par livraison
            </Button>
          </Space>
          {usersSort.map((u) => (
            <div
              key={`user-${u.id}`}
              style={{
                marginBottom: 10,
                border: selectUserId === u.id && '2px solid red',
                opacity: u.userArticles.find((f) => !f.isDelivered) ? 1 : 0.5,
              }}
            >
              <div
                style={{
                  padding: 10,
                  backgroundColor: '#F0F0F0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    type="primary"
                    style={{ marginRight: 10 }}
                    onClick={() => (selectUserId === u.id ? setSelectUserId(null) : setSelectUserId(u.id))}
                  >
                    {selectUserId === u.id ? '-' : '+'}
                  </Button>
                  <div style={{ fontSize: 14, fontWeight: 600 }}>
                    {`${capitalizeFirstLetter(u.firstName) || ''} ${u.lastName?.toUpperCase()
                      || ''} ${u.auth ? `(${u.auth.email})` : ''} | ${u.participation.code}`}
                  </div>
                </div>
                <Space>
                  <Button
                    type="primary"
                    onClick={() => {
                      if (currentDay) {
                        updateUserEventDays({
                          ...myContext,
                          dayId: currentDay.id,
                          userId: u.id,
                          day: { present: true, presentAt: moment().format('HH:mm') },
                        });
                      }
                    }}
                    loading={isEmarging}
                    disabled={!currentDay}
                  >
                    Émarger
                  </Button>
                  <Button type="primary" onClick={() => setScanUserId(u.id)}>
                    New badge
                  </Button>
                  <Button type="primary" onClick={() => onShow(u.id)}>
                    Modifier
                  </Button>
                  <Button onClick={() => nav(`/users/${u.id}`, { newTab: true })}>Open</Button>
                  <Button
                    onClick={async () => {
                      await send({
                        ...myContext,
                        templateId: settings?.FO_PAY_SEND_PAIEMENT_TEMPLATE_ID,
                        accountId: settings?.FO_PAY_SEND_PAIEMENT_ACCOUNT_ID,
                        usersId: [u.id],
                        variables: { url: `${settings?.WEBSITE_URL}/register?token=${token}` },
                      });
                    }}
                    loading={isCreatingToken}
                    disabled={!token}
                  >
                    Lien
                  </Button>
                </Space>
              </div>
              {selectUserId === u.id
                && u.days.map((day) => (
                  <div>
                    <div
                      style={{ background: '#4F4F4F', color: 'white', padding: '4px 8px' }}
                    >
                      {`${getDate(day.day)}`}
                    </div>
                    {day.userArticles.map((ua) => (
                      <div
                        style={{
                          padding: 10,
                          backgroundColor: '#F0F0F0',
                          borderTop: '1px solid black',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div>
                          <div>{`${ua.article.label}`}</div>
                          {ua.isDelivered && (
                            <div style={{ fontSize: 12 }}>
                              {`Livré par ${capitalizeFirstLetter(ua.deliveredBy?.firstName)
                                || ''} ${ua.deliveredBy?.lastName?.toUpperCase() || ''} le ${dayjs(
                                ua.deliveredAt,
                              ).format('DD/MM/YYYY à HH:mm')}`}
                            </div>
                          )}
                          {ua.comment && (
                            <div style={{ fontSize: 12 }}>{`Commentaire: ${ua.comment}`}</div>
                          )}
                        </div>
                        <Space>
                          <Button
                            type="primary"
                            onClick={() => {
                              if (ua.isDelivered) {
                                confirm({
                                  title: 'Voulez-vous vraiment supprimer cette livraison ?',
                                  icon: <ExclamationCircleOutlined />,
                                  okText: 'Oui',
                                  okType: 'danger',
                                  cancelText: 'Non',
                                  onOk() {
                                    updateArticle({
                                      ...myContext,
                                      userId: u.id,
                                      dayId: ua.dayId,
                                      articleId: ua.articleId,
                                      body: {
                                        isDelivered: false,
                                        deliveredAt: null,
                                        deliveredIdBy: null,
                                      },
                                    });
                                  },
                                });
                              } else {
                                updateArticle({
                                  ...myContext,
                                  userId: u.id,
                                  dayId: ua.dayId,
                                  articleId: ua.articleId,
                                  body: {
                                    isDelivered: true,
                                    deliveredAt: new Date(),
                                    deliveredIdBy: me?.id,
                                  },
                                });
                              }
                            }}
                            loading={isUpdatingArticle}
                            danger={ua.isDelivered}
                          >
                            {ua.isDelivered ? 'Reprendre' : 'Livrer'}
                          </Button>
                          <Button
                            type="primary"
                            onClick={() => setCommentDeliveryId([
                              ua,
                              (comment) => {
                                updateArticle({
                                  ...myContext,
                                  userId: u.id,
                                  dayId: ua.dayId,
                                  articleId: ua.articleId,
                                  body: { comment },
                                });
                              },
                            ])}
                            disabled={!ua.isDelivered}
                          >
                            Commenter
                          </Button>

                          <Button
                            type="primary"
                            onClick={() => confirm({
                              title: 'Voulez-vous vraiment supprimer cet article ?',
                              icon: <ExclamationCircleOutlined />,
                              okText: 'Oui',
                              okType: 'danger',
                              cancelText: 'Non',
                              onOk() {
                                removeArticle({
                                  ...myContext,
                                  userId: u.id,
                                  dayId: ua.dayId,
                                  articleId: ua.articleId,
                                });
                              },
                            })}
                            loading={isRemovingArticle}
                            disabled={ua.isDelivered}
                            danger
                          >
                            Supprimer
                          </Button>
                        </Space>
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function VoucherDetailsScreen() {
  const [scanUserId, setScanUserId] = useState();
  const [selectUserId, setSelectUserId] = useState();
  const [[ua, setComment], setCommentDeliveryId] = useState([null, null]);

  const { myContext } = useMyContext();

  const [updateUserEvent] = useUpdateUserEventMutation();

  return (
    <div>
      {scanUserId && (
        <div>
          <Button style={{ marginBottom: 10 }} onClick={() => setScanUserId(null)}>
            Retour
          </Button>
          <QReader
            onChange={async (newCode) => {
              if (newCode) {
                updateUserEvent({ ...myContext, userId: scanUserId, userEvent: { code: newCode } });
                setScanUserId(null);
              }
            }}
          />
        </div>
      )}
      {setComment && (
        <Form
          layout="vertical"
          onFinish={async (v) => {
            await setComment(v.comment);
            setCommentDeliveryId([null, null]);
          }}
          initialValues={ua}
        >
          <Button style={{ marginBottom: 10 }} onClick={() => setCommentDeliveryId([null, null])}>
            Retour
          </Button>
          <Form.Item name="comment" label="Commentaire">
            <Input.TextArea rows={4} />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Enregistrer
          </Button>
        </Form>
      )}
      {!scanUserId && !ua && (
        <ParticipationsAdd>
          <ParticipationsList
            setScanUserId={setScanUserId}
            selectUserId={selectUserId}
            setSelectUserId={setSelectUserId}
            setCommentDeliveryId={setCommentDeliveryId}
          />
        </ParticipationsAdd>
      )}
    </div>
  );
}

export default VoucherDetailsScreen;
