import { Button, Table } from 'antd';
import React from 'react';
import { useMyContext } from '../../../hooks/useMyContext';
import { useGetFormsQuery, useRemoveFormMutation } from '../../../api/v2/forms';
import FormsEdition, { types } from './Edition';
import ButtonConfirm from '../../../components/ButtonConfirm';

export default function FormsV2Page() {
  const { myContext, event } = useMyContext();

  const { data: forms, isFetching } = useGetFormsQuery(myContext);
  const [remove] = useRemoveFormMutation();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <FormsEdition>
        <Button type="primary">Créer un formulaire</Button>
      </FormsEdition>
      <Table
        scroll={{ x: true }}
        dataSource={forms?.filter((f) => (event ? f.eventId === event.id : true))}
        columns={[
          { title: 'Nom', dataIndex: 'name' },
          { title: 'Type de données', dataIndex: 'type', render: (v) => types.find((f) => f.value === v)?.label },
          { title: 'Nombre de champs', dataIndex: 'models', render: (v) => v.length },
          {
            title: '',
            render: (v) => (
              <div style={{ display: 'flex', gap: 8 }}>
                <FormsEdition values={v}>
                  <Button type="primary">Modifier</Button>
                </FormsEdition>
                <ButtonConfirm
                  title="Voulez-vous vraiment supprimer ce formulaire ?"
                  onOk={() => remove({ ...myContext, id: v.id })}
                >
                  <Button type="primary" danger ghost>Supprimer</Button>
                </ButtonConfirm>
              </div>
            ),
          }]}
        loading={isFetching}
      />
    </div>
  );
}
