import { Button, Modal, Table } from 'antd';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { useEventsGetByDomainQuery } from '../../api/v2/events';
import { useLayout } from '../../hooks/useLayout';
import { useMyContext } from '../../hooks/useMyContext';
import useRights from '../../hooks/useRights';
import useSettingsBo from '../../hooks/useSettingsBO';

export default function ModalEvent() {
  const [open, setOpen] = useState(false);
  const [currentYear, setCurrentYear] = useState();

  const { myContext, selectEvent, event } = useMyContext();
  const { hasRoleOnEvent } = useRights();
  const { sidebarWidth } = useLayout();
  const { primaryColor } = useSettingsBo();

  const { data: events, isFetching } = useEventsGetByDomainQuery({ ...myContext, archive: false });

  const years = useMemo(() => events?.reduce((prev, curr) => {
    const year = Number(format(curr.start, 'yyyy'));
    const find = prev.find((f) => f === year);
    if (!find) {
      return [...prev, year];
    }
    return prev;
  }, []).sort((a, b) => b - a), [events]);

  const eventsByYear = useMemo(() => events
    ?.filter((f) => Number(format(f.start, 'yyyy')) === currentYear)
    .filter((f) => hasRoleOnEvent(f.id)) || [], [currentYear, events, hasRoleOnEvent]);

  useEffect(() => {
    if (years) {
      setCurrentYear(years[0]);
    }
  }, [years]);

  return (
    <div>
      <Modal
        title="Choisir un événement"
        onCancel={() => setOpen(false)}
        open={open}
        footer={(
          <div style={{ display: 'flex', gap: 8 }}>
            <Button
              onClick={() => {
                selectEvent(undefined);
                setOpen(false);
              }}
              type="primary"
              style={{ width: '100%' }}
            >
              Revenir sur le domaine
            </Button>
            <Button onClick={() => setOpen(false)} type="primary" ghost>Fermer</Button>
          </div>
        )}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 8, marginBottom: 8 }}>
          {years?.map((y) => (
            <Button
              onClick={() => setCurrentYear(y)}
              type="primary"
              ghost={y !== currentYear}
            >
              {y}
            </Button>
          ))}
        </div>
        <Table
          loading={isFetching}
          showHeader={false}
          size="small"
          pagination={false}
          dataSource={eventsByYear}
          columns={[
            {
              render: (e) => (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div>
                    {e.name}
                  </div>
                  {e.start && (
                    <div style={{ fontSize: 12 }}>
                      {format(e.start, 'dd/MM/yyyy')}
                    </div>
                  )}
                </div>
              ),
            },
            {
              render: (e) => (
                <Button
                  onClick={() => {
                    selectEvent(e.id);
                    setOpen(false);
                  }}
                  type="primary"
                  style={{ width: '100%' }}
                >
                  Choisir
                </Button>
              ),
            },
          ]}
        />
      </Modal>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: primaryColor,
          color: 'white',
          padding: '8px 16px',
          borderRadius: 8,
          gap: 4,
          cursor: 'pointer',
        }}
        onClick={() => setOpen(true)}
        aria-hidden
      >
        <div
          style={{
            maxWidth: sidebarWidth - 40,
            fontSize: 12,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            textAlign: 'center',
          }}
        >
          {event?.name || 'Aucun événment'}
        </div>
        {event?.start && (
          <div style={{ fontSize: 12 }}>
            {format(event.start, 'dd/MM/yyyy')}
          </div>
        )}
      </div>
    </div>
  );
}
