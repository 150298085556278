import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Spin } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetUserQuery, useUpdateUserMutation } from '../../../api/v2/users';
import { useMyContext } from '../../../hooks/useMyContext';
import UserDetailsRole from './Role';
import UserToken from './Token';
import UserLogs from './Logs';
import useNav from '../../../hooks/useNav';
import { useGetParticipationQuery } from '../../../api/v2/participations';
import useSettingsBo from '../../../hooks/useSettingsBO';
import FormBuilder from '../../../components/FormBuilder';
import useRights from '../../../hooks/useRights';
import ButtonConfirm from '../../../components/ButtonConfirm';

function Language({ language }) {
  if (language === 'FR') {
    return '🇫🇷';
  }
  if (language === 'EN') {
    return '🇺🇸';
  }
  return '';
}

export function Profile() {
  const { myContext } = useMyContext();
  const { userId } = useParams();
  const { data: user } = useGetUserQuery({ ...myContext, userId });

  return (
    <div style={{ display: 'flex', gap: 8 }}>
      <Avatar shape="square" size={80} icon={<UserOutlined />} src={user.fileUrl} />
      {/* <UserFaces /> */}
      <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
        <div style={{ fontSize: 18, fontWeight: '600' }}>
          {`${user.firstName || ''} ${user.lastName || ''}`}
          {' '}
          <Language language={user.language} />
        </div>
        {user.email && <div>{`${user.email || ''}`}</div>}
        {(user.mobile || user.phone) && (
          <div>
            {`${user.mobile || ''} ${(user.mobile && user.phone) ? ' / ' : ''} ${user.phone || ''}`}
          </div>
        )}
      </div>
    </div>
  );
}

function Left() {
  const { myContext, event } = useMyContext();
  const { userId } = useParams();
  const { hasRight } = useRights();
  const nav = useNav();
  const settings = useSettingsBo();

  const { data: participation } = useGetParticipationQuery({ ...myContext, userId });
  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <Profile />
      <div style={{ display: 'flex', gap: 8, flexWrap: 'wrap', flexDirection: 'column' }}>
        <UserDetailsRole />
        <div style={{ display: 'flex', gap: 8 }}>
          <FormBuilder userId={userId} title="Modifier cet utilisateur" withModal>
            <Button style={{ flexGrow: 1 }} size="small" type="primary">Modifier</Button>
          </FormBuilder>
          <ButtonConfirm
            title="Voulez-vous vraiment archiver cet utilisateur ?"
            onOk={() => updateUser({ ...myContext, userId, user: { archive: true } })}
          >
            <Button size="small" type="primary" loading={isUpdating} danger>Archiver</Button>
          </ButtonConfirm>
        </div>
        <div style={{ display: 'flex', gap: 8 }}>
          <UserToken />
        </div>
        <div style={{ display: 'flex', gap: 8 }}>
          {hasRight('VOUCHERS_V2') && event && (
            <Button
              type="primary"
              size="small"
              onClick={(e) => nav(`/vouchers/${participation?.voucherCode}`, { e })}
              disabled={!participation?.voucherCode}
              style={{ flexGrow: 1 }}
            >
              Voucher
            </Button>
          )}
          {settings?.shooting ? (
            <Button
              size="small"
              type="primary"
              onClick={(e) => nav('/photos/shooting', { e, query: { code: participation.code } })}
              disabled={!participation}
              style={{ flexGrow: 1 }}
            >
              Shooting
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

function Right() {
  return (
    <div className="hide-1000" style={{ flexGrow: 1, minWidth: 250 }}>
      <UserLogs />
    </div>
  );
}

export default function UserDetails() {
  const { myContext } = useMyContext();
  const { userId } = useParams();

  const { data: user, isFetching } = useGetUserQuery({ ...myContext, userId });

  return (
    <>
      <Spin spinning={isFetching} fullscreen />
      {user && (
        <div style={{ display: 'flex', gap: 8, justifyContent: 'space-between' }}>
          <Left />
          <Right />
        </div>
      )}
    </>
  );
}
