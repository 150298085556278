import queryString from 'query-string';
import apiV2 from '.';

export const modelsApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    // updateModel: builder.mutation({
    //   query: ({ domainId, modelId, model }) => ({
    //     url: `/domains/${domainId}/models/${modelId}`,
    //     method: 'PUT',
    //     body: { model },
    //   }),
    //   invalidatesTags: ['MODELS'],
    // }),
    getModels: builder.query({
      query: ({ domainId, eventId, onlyDomain }) => (eventId
        ? `/domains/${domainId}/events/${eventId}/models` : `/domains/${domainId}/models?${queryString.stringify({ onlyDomain })}`),
      transformResponse: (response) => response.data.models,
      providesTags: ['MODELS'],
    }),
    getModelVirtuals: builder.query({
      query: ({ domainId, eventId }) => (eventId
        ? `/domains/${domainId}/events/${eventId}/models/virtuals`
        : `/domains/${domainId}/models/virtuals`),
      transformResponse: (response) => response.data.modelVirtuals,
      providesTags: ['MODEL_VIRTUALS', 'MODELS'],
    }),
    createModelVirtual: builder.mutation({
      query: ({ domainId, eventId, modelVirtual }) => ({
        url: (eventId
          ? `/domains/${domainId}/events/${eventId}/models/virtuals`
          : `/domains/${domainId}/models/virtuals`),
        method: 'POST',
        body: { modelVirtual },
      }),
      invalidatesTags: ['MODEL_VIRTUALS'],
    }),
    updateModelVirtual: builder.mutation({
      query: ({ domainId, modelVirtualId, modelVirtual }) => ({
        url: `/domains/${domainId}/models/virtuals/${modelVirtualId}`,
        method: 'PUT',
        body: { modelVirtual },
      }),
      invalidatesTags: ['MODEL_VIRTUALS'],
    }),
    removeModelVirtual: builder.mutation({
      query: ({ domainId, modelVirtualId }) => ({
        url: `/domains/${domainId}/models/virtuals/${modelVirtualId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['MODEL_VIRTUALS'],
    }),
    migrateModelVirtuals: builder.mutation({
      query: ({ domainId, eventId }) => ({
        url: (eventId
          ? `/domains/${domainId}/events/${eventId}/models/virtuals/migrate`
          : `/domains/${domainId}/models/virtuals/migrate`),
        method: 'POST',
      }),
      invalidatesTags: ['PARTICIPATION'],
    }),
    updateModel: builder.mutation({
      query: ({ domainId, eventId, id, body }) => ({
        url: (eventId
          ? `/domains/${domainId}/events/${eventId}/models/${id}`
          : `/domains/${domainId}/models/${id}`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['MODELS'],
    }),
    createModel: builder.mutation({
      query: ({ domainId, eventId, body }) => ({
        url: (eventId
          ? `/domains/${domainId}/events/${eventId}/models`
          : `/domains/${domainId}/models`),
        method: 'POST',
        body,
      }),
      invalidatesTags: ['MODELS'],
    }),
    removeModel: builder.mutation({
      query: ({ domainId, id }) => ({
        url: `/domains/${domainId}/models/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['MODELS'],
    }),
  }),
});

export const {
  useGetModelsQuery,
  useUpdateModelMutation,
  useGetModelVirtualsQuery,
  useCreateModelVirtualMutation,
  useUpdateModelVirtualMutation,
  useRemoveModelVirtualMutation,
  useMigrateModelVirtualsMutation,
  useCreateModelMutation,
  useRemoveModelMutation,
} = modelsApi;
