/* eslint-disable jsx-a11y/media-has-caption */
import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { useAttendancesScanMutation } from '../../api/v2/attendances';
import QReader from '../../components/QReader';
import { useMyContext } from '../../hooks/useMyContext';
import useSettingsBo from '../../hooks/useSettingsBO';
import { useSendEmailMutation } from '../../api/v2/emails';

function IndicatorCircle({ type }) {
  if (type === 'error') {
    return <CheckCircleFilled style={{ color: '#f5222d' }} />;
  }

  return <CheckCircleFilled style={{ color: '#52c41a' }} />;
}

function Resume({ data }) {
  const audioRef = useRef(null);

  const { user, passageLast, passagesTotal, type, company, typo } = data;

  useEffect(() => {
    if (audioRef.current && passageLast?.type === 'IN' && type === 'IN') {
      audioRef.current.play();
    }
  }, [audioRef.current]);

  return (
    <div style={{
      color: 'rgba(0, 0, 0, 0.88)',
      fontWeight: 600,
      fontSize: 20,
      lineHeight: 1.5,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 8,
    }}
    >
      <audio ref={audioRef} src="/assets/songs/error.mp3" />
      <div style={{ height: 8 }} />
      {user && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', lineHeight: 1.2 }}>
          <div style={{ fontSize: 30 }}>
            {`${user.firstName || ''} ${user.lastName || ''}`}
          </div>
          <div style={{ fontSize: 14 }}>
            {user.email ? ` (${user.email})` : ''}
          </div>
        </div>
      )}
      <div style={{ height: 8 }} />
      {company && <div>{`${company.name}`}</div>}
      {typo && <div>{`${typo.label}`}</div>}
      <div style={{ height: 8 }} />
      {passageLast?.type === 'IN' && type === 'IN' && (
        <div
          className="blink-text"
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'red', lineHeight: 1.2 }}
        >
          <div>{`Badge déjà scanné ${passagesTotal} fois`}</div>
          <div>{`Dernier scan à ${format(new Date(passageLast.createdAt), 'HH:mm')}`}</div>
        </div>
      )}
    </div>
  );
}

export default function ScanPage() {
  const [modal, setModal] = useState();
  const [entrance, setEntrance] = useState(localStorage.getItem('Scan_entrance') || 'IN');

  const { myContext, event } = useMyContext();
  const [modal2, contextHolder] = Modal.useModal();
  const settings = useSettingsBo();

  const [scan, { isLoading }] = useAttendancesScanMutation();
  const [send] = useSendEmailMutation();

  const sendCode = async (code) => {
    if (modal) {
      modal.destroy();
    }

    const data = await scan({ ...myContext, code, eventIdB: event.eventId, type: entrance }).unwrap();

    let instance;
    if (data.error === 'BADGE_NOT_FOUND') {
      instance = modal2.error({
        title: 'Badge introuvable',
      });
    } else if (data.user) {
      instance = modal2.success({
        title: `Badge N°${code}`,
        content: <Resume data={data} />,
        styles: settings?.[`EVENT_${data.eventId}_COLOR`]
          ? { content: { border: `5px solid ${settings?.[`EVENT_${data.eventId}_COLOR`]}` } }
          : {},
        footer: null,
      });
      if (settings?.scanWelcomeTemplate) {
        send({ ...myContext, userId: data.user.id, templateId: settings.scanWelcomeTemplate });
      }
    }

    setModal(instance);

    setTimeout(() => {
      setModal(null);
      instance.destroy();
    }, 3 * 1000);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      <div style={{ display: 'flex', gap: 8 }}>
        <Button
          type="primary"
          ghost={entrance !== 'IN'}
          onClick={() => {
            setEntrance('IN');
            localStorage.setItem('Scan_entrance', 'IN');
          }}
        >
          Entrée
        </Button>
        <Button
          type="primary"
          ghost={entrance !== 'OUT'}
          onClick={() => {
            setEntrance('OUT');
            localStorage.setItem('Scan_entrance', 'OUT');
          }}
        >
          Sortie
        </Button>
      </div>

      <QReader onChange={sendCode} loading={isLoading} />

      {contextHolder}
    </div>
  );
}
