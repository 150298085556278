import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Modal, Select } from 'antd';
import { useMyContext } from '../../../hooks/useMyContext';
import { useDownloadBadgeMutation, useGetBadgesQuery } from '../../../api/v1/badges';
import SendEmail, { boEmails } from '../../../components/SendEmail';
import { useGetParticipationQuery, useUpdateParticipationMutation } from '../../../api/v2/participations';
import QReader from '../../../components/QReader';

function NewCode() {
  const [open, setOpen] = useState(false);

  const { myContext } = useMyContext();
  const { userId } = useParams();

  const [update, { isLoading }] = useUpdateParticipationMutation();

  return (
    <>
      <Button type="primary" onClick={() => setOpen(true)}>Affecter un nouveau badge</Button>
      <Modal title="Affecter un nouveau badge" open={open} footer={null} onCancel={() => setOpen(false)}>
        <QReader
          onChange={async (code) => {
            await update({ ...myContext, userId, participation: { code } }).unwrap();
            setOpen(false);
          }}
          loading={isLoading}
        />
      </Modal>
    </>
  );
}

export default function UserEventBadges() {
  const [badgeId, setBadgeId] = useState();

  const { myContext, settings } = useMyContext();
  const { userId } = useParams();

  const { data: participation } = useGetParticipationQuery({ ...myContext, userId });
  const { data: badges } = useGetBadgesQuery({ ...myContext });
  const [downloadBadge, { data: url, isLoading }] = useDownloadBadgeMutation();

  useEffect(() => {
    if (badges?.length > 0) {
      const id = settings?.typosV2?.[participation.typoId]?.BADGE_ID || settings?.badgeId || badges[0].id;
      setBadgeId(id);
    }
  }, [badges, settings, participation]);

  useEffect(() => {
    if (badgeId) {
      downloadBadge({ ...myContext, badgeId, userId });
    }
  }, [badgeId]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <NewCode />
      <div style={{ display: 'flex', gap: 8, flexWrap: 'wrap' }}>
        <Select
          popupMatchSelectWidth={false}
          style={{ flexGrow: 1, maxWidth: 200 }}
          options={badges?.map((b) => ({ label: b.name, value: b.id }))}
          value={badgeId}
          onChange={setBadgeId}
        />
        <Button type="primary" href={url} target="_blank" loading={isLoading} disabled={!url}>Télécharger le badge</Button>
        <SendEmail email={boEmails.EMAILS_SEND_BADGE} />
      </div>
    </div>
  );
}
