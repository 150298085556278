import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Button, Modal, Select } from 'antd';
import { useGetRolesQuery, useSetUserRoleMutation } from '../../../api/v2/roles';
import { useMyContext } from '../../../hooks/useMyContext';
import useRights from '../../../hooks/useRights';
import { useGetUserQuery, useUpdateUserMutation } from '../../../api/v2/users';

export default function UserDetailsRole() {
  const [open, setOpen] = useState(false);

  const { myContext } = useMyContext();
  const { userId } = useParams();
  const { hasRight } = useRights();

  const { data: user } = useGetUserQuery({ ...myContext, userId });
  const { data, isFetching } = useGetRolesQuery({ ...myContext, userId });
  const [setUserRole, { isLoading }] = useSetUserRoleMutation();
  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();

  // if (!hasRight('ROLES') || !user) {
  //   return null;
  // }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <Button size="small" type="primary" onClick={() => setOpen(true)}>
        {`${user.isExhibitor ? 'Exposant' : 'Visiteur'} ${data?.current?.label ? `(${data.current.label})` : ''}`}
      </Button>
      <Modal
        open={open}
        title="Modifier le rôle"
        footer={null}
        onCancel={() => setOpen(false)}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <Select
            loading={isUpdating}
            value={user.isExhibitor}
            onChange={(isExhibitor) => updateUser({ ...myContext, userId, user: { isExhibitor } })}
            options={[{ label: 'Visiteur', value: 0 }, { label: 'Exposant', value: 1 }]}
          />
          {hasRight('ROLES') && (
            <Select
              placeholder="Choisir le rôle"
              onChange={(roleId) => setUserRole({ ...myContext, userId, roleId: roleId || 0 })}
              value={data?.current?.roleId}
              options={data?.roles?.map((m) => ({ label: m.label, value: m.id }))}
              loading={isFetching || isLoading}
              disabled={!data?.canUpdate}
              allowClear
            />
          )}
          {data?.canUpdate === false && (
            <Alert
              type="warning"
              message={myContext?.eventId
                ? 'Cet utilisateur a déjà un rôle sur ce domaine'
                : 'Cet utilisateur a déjà des rôles sur un ou plusieurs événements'}
            />
          )}
          <Button type="primary" style={{ width: '100%' }} onClick={() => setOpen(false)}>Fermer</Button>
        </div>
      </Modal>
    </div>
  );
}
