import { Alert, Button, Divider, Form, Input, Select, Typography } from 'antd';
import React, { useState } from 'react';
import { useParticipations } from './useParticipations';
import { useMyContext } from '../../../../hooks/useMyContext';
import { useUpdateUserEventMutation, useUpdateUserMutation } from '../../../../api/v1/users';
import { useGetMyCompaniesQuery } from '../../../../api/v1/companies';
import { useAddCompanionMutation } from '../../../../api/v1/companions';
import { useAddEmployeeMutation } from '../../../../api/v1/employees';

const { Text } = Typography;

function ParticipationsCreateUser() {
  const [step, setStep] = useState(0);
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const { myContext } = useMyContext();
  const { setUserId, setStep: setStep2, myUserId } = useParticipations();
  const [form] = Form.useForm();

  const { data: companies } = useGetMyCompaniesQuery({ ...myContext, userId: myUserId });
  const [addCompanion] = useAddCompanionMutation();
  const [addEmployee] = useAddEmployeeMutation();
  const [updateUserEvent] = useUpdateUserEventMutation();
  const [updateUser] = useUpdateUserMutation();

  const onSubmit = async (employee) => {
    setIsLoading(true);
    setError();
    try {
      if (step === 0) {
        const [u] = await addCompanion({ ...myContext, user: employee, userId: myUserId }).unwrap();
        if (u?.isAlreadyRegistered) {
          setError(
            'Ce testeur est déjà inscrit à l’événement. Si cette adresse email vous appartient, veuillez contacter l’organisateur.',
          );
        } else {
          setUser(u);
          setUserId(u.id);
          setStep(1);
          form.setFieldsValue({ ...u, companyId: companies[0]?.id });
        }
      } else {
        const e = await addEmployee({
          ...myContext,
          userId: user.id,
          companyId: employee.companyId,
        }).unwrap();
        await updateUserEvent({ ...myContext, userId: user.id, eventUser: { employeeId: e.id } });
        await updateUser({ ...myContext, userId: user.id, user: employee }).unwrap();
        setStep2(2);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form
      form={form}
      initialValues={{ companyId: companies?.length === 1 ? companies[0]?.id : null }}
      layout="vertical"
      onFinish={onSubmit}
    >
      <Text type="danger" strong>
        Information importante :
      </Text>
      <div>
        une
        {' '}
        <strong>adresse email unique</strong>
        {' '}
        est nécessaire pour chacun des participants
        inscrits.
      </div>
      <div>
        Ces informations resteront confidentielles et seront strictement utilisées dans le contexte
        du SKI FORCE Pro 2024.
      </div>

      <Divider />

      <Form.Item
        label="Email"
        name="email"
        rules={[{ type: 'email', required: true, message: "Le format de l'email est incorrect" }]}
      >
        <Input type="email" disabled={step === 1} />
      </Form.Item>

      {error && (
        <Form.Item>
          <Alert message={error} type="error" showIcon />
        </Form.Item>
      )}

      {step === 1 && (
        <>
          <Form.Item
            label="Magasin"
            name="companyId"
            rules={[{ required: true, message: 'Le magasin est obligatoire' }]}
          >
            <Select
              options={companies?.map((company) => ({ label: company.name, value: company.id }))}
            />
          </Form.Item>

          <Form.Item
            label="Prénom"
            name="firstName"
            rules={[{ required: true, message: 'Le prénom est obligatoire' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Nom"
            name="lastName"
            rules={[{ required: true, message: 'Le nom est obligatoire' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Téléphone" name="mobile">
            <Input />
          </Form.Item>
        </>
      )}

      <Form.Item>
        <Typography.Text type="danger">* champs obligatoires</Typography.Text>
      </Form.Item>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={() => setStep2(0)}>RETOUR</Button>
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Suivant
        </Button>
      </div>
    </Form>
  );
}

export default ParticipationsCreateUser;
