import { Button, Spin } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetUserQuery, useUpdateUserMutation } from '../../api/v2/users';
import FlexGrow from '../../components/FlexGrow';
import IFrame from '../../components/IFrame';
import { useMyContext } from '../../hooks/useMyContext';
import UserDetails, { Profile } from './Details';
import UserEvent from './Event';
import UserTabs from './Tabs';

export default function UserPage() {
  const [version, setVersion] = useState(localStorage.getItem('user_version') || '2');

  const { myContext, event } = useMyContext();
  const { userId } = useParams();

  const { data: user, isLoading } = useGetUserQuery({ ...myContext, userId });
  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 8 }}>
        <Button
          type="link"
          onClick={() => {
            const newVersion = version === '1' ? '2' : '1';
            setVersion(newVersion);
            localStorage.setItem('user_version', newVersion);
          }}
        >
          {version === '1' ? 'Revenir sur la nouvelle version' : "Utiliser l'ancienne version"}
        </Button>
      </div>

      {isLoading && <Spin spinning={isLoading} fullscreen />}
      {/* {user && version === '2' && (
        <div
          style={{
            display: 'flex',
            gap: 16,
            flexWrap: 'wrap',
            alignItems: 'flex-start',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', gap: 8, flexGrow: 1 }}>
            <UserDetails />
            <UserTabs />
          </div>
          <UserEvent />
        </div>
      )} */}
      {user && version === '1' && <FlexGrow><IFrame path={`/users/${user.id}`} /></FlexGrow>}
      {user && version === '2' && user.archive ? (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <Profile />
          <div>
            <Button
              size="small"
              type="primary"
              onClick={() => updateUser({ ...myContext, userId, user: { archive: false } })}
              loading={isUpdating}
            >
              Réactiver cet utilisateur
            </Button>
          </div>
        </div>
      ) : null}
      {user && version === '2' && !user.archive && (
        <div style={{ display: 'flex', gap: 8, flexWrap: 'wrap' }}>
          <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 8, maxWidth: '100%', width: 700 }}>
            <div>
              <UserDetails />
            </div>
            <div>
              <UserTabs />
            </div>
          </div>
          {event && (
            <div style={{ flexGrow: 1, maxWidth: '100%', width: 300 }}>
              <UserEvent />
            </div>
          )}
        </div>
      )}
    </>
  );
}
