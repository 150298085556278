import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Table } from 'antd';
import React, { useState } from 'react';
import { useGetEmployeesQuery, useRemoveEmployeeMutation } from '../../../../api/v2/employees';
import { useMyContext } from '../../../../hooks/useMyContext';

export default function RemoveCoworker({ userId }) {
  const [open, setOpen] = useState(false);

  const { myContext } = useMyContext();

  const { data: companies } = useGetEmployeesQuery({ ...myContext, userId });
  const [removeEmployee, { isLoading: isRemoving }] = useRemoveEmployeeMutation();

  return (
    <>
      <Button type="primary" onClick={() => setOpen(true)} icon={<MinusCircleOutlined />} danger />
      <Modal title="Retirer un collaborateur" open={open} footer={null} onCancel={() => setOpen(false)}>
        <Table
          size="small"
          pagination={false}
          dataSource={companies}
          scroll={{ x: true }}
          columns={[
            { title: 'Nom', dataIndex: 'name' },
            { title: 'Index', dataIndex: 'siren' },
            { title: 'Ville', dataIndex: 'city' },
            {
              render: (c) => (
                <div style={{ display: 'flex', gap: 8 }}>
                  <Button
                    type="primary"
                    onClick={() => {
                      if (companies?.length === 1) {
                        setOpen(false);
                      }
                      removeEmployee({ ...myContext, companyId: c.id, userId });
                    }}
                    loading={isRemoving}
                    danger
                  >
                    Retirer de cette société
                  </Button>
                </div>
              ),
            },
          ]}
        />
      </Modal>
    </>
  );
}
