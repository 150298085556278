import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import IFrame from '../../components/IFrame';

export default function ShootingPage() {
  const [search] = useSearchParams();

  const code = useMemo(() => search.get('code'), [search]);

  return <IFrame path="/shooting" query={{ code }} />;
}
