/* eslint-disable max-len */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { memo } from 'react';
import queryString from 'query-string';
import { useMyContext } from '../hooks/useMyContext';
import FlexGrow from './FlexGrow';

let hostName = 'https://bo.gayakoa.com';
if (window.location.hostname === 'localhost') {
  hostName = 'http://localhost:4000';
} else if (window.location.hostname.includes('.dev')) {
  hostName = 'https://bo.dev.gayakoa.com';
}

const IFrame = memo(({ path, query }) => {
  const { myContext } = useMyContext();

  const { domainId, eventId } = myContext;

  return (
    <FlexGrow>
      <iframe
        style={{ flexGrow: 1 }}
        src={`${hostName}${path}?${queryString.stringify({ domain: domainId, event: eventId, fullScreen: true, token: localStorage.getItem('token'), ...(query || {}) })}`}
        // src={`${hostName}${path}?domain=${domainId}${eventId ? `&event=${eventId}` : ''}&fullScreen=true&token=${localStorage.getItem('token')}`}
        width="100%"
        allow="camera; microphone"
      />
    </FlexGrow>
  );
});

export default IFrame;
