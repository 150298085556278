import apiV2 from '.';

export const participationsApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    bulkParticipation: builder.mutation({
      query: ({
        domainId, eventId, toEventId, filters, headers,
      }) => ({
        url: `/domains/${domainId}/participations/bulk`,
        method: 'POST',
        body: {
          fromEventId: eventId,
          toEventId,
          filters,
          headers,
        },
      }),
      invalidatesTags: ['PARTICIPATION'],
    }),
    bulkRemoveParticipations: builder.mutation({
      query: ({ domainId, eventId, filters, headers }) => ({
        url: `/domains/${domainId}/events/${eventId}/participations/bulk/remove`,
        method: 'POST',
        body: { filters, headers },
      }),
      invalidatesTags: ['PARTICIPATION'],
    }),
    getParticipation: builder.query({
      query: ({ domainId, eventId, userId }) => ({
        url: `/domains/${domainId}/events/${eventId}/users/${userId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.participation,
      providesTags: ['PARTICIPATION'],
    }),
    getParticipations: builder.query({
      query: ({ domainId, userId }) => ({
        url: `/domains/${domainId}/users/${userId}/participations`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.participations,
      providesTags: ['PARTICIPATION', 'EVENTS'],
    }),
    updateParticipation: builder.mutation({
      query: ({ domainId, eventId, userId, participation, days }) => ({
        url: `/domains/${domainId}/events/${eventId}/users/${userId}`,
        method: 'PUT',
        body: { participation, days },
      }),
      transformResponse: (response) => response.data.participation,
      invalidatesTags: ['PARTICIPATION'],
    }),
    removeParticipation: builder.mutation({
      query: ({ domainId, eventId, userId, body }) => ({
        url: `/domains/${domainId}/events/${eventId}/users/${userId}`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['PARTICIPATION'],
    }),
    getResume: builder.query({
      query: ({ domainId, eventId, userId }) => ({
        url: `/domains/${domainId}/events/${eventId}/users/${userId}/invoices/resume`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data,
      providesTags: ['PARTICIPATION', 'EVENTS'],
    }),
  }),
});

export const {
  useBulkParticipationMutation,
  useBulkRemoveParticipationsMutation,
  useGetParticipationQuery,
  useUpdateParticipationMutation,
  useRemoveParticipationMutation,
  useGetParticipationsQuery,
  useGetResumeQuery,
} = participationsApi;
