import { Alert, Button, Form, Select, Switch } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetFoSettingsQuery, useUpdateFoSettingsMutation } from '../../../api/v2/fo';
import MyColorPicker from '../../../components/MyColorPicker';
import { useMyContext } from '../../../hooks/useMyContext';
import useNav from '../../../hooks/useNav';
import { useGetFormsQuery } from '../../../api/v2/forms';

export default function SettingsInformationsPage() {
  const { myContext } = useMyContext();
  const [form] = Form.useForm();
  const { tab } = useParams();
  const nav = useNav();

  const { data } = useGetFoSettingsQuery({ ...myContext });
  const [update, { isLoading, isSuccess, reset }] = useUpdateFoSettingsMutation();
  const { data: forms2 } = useGetFormsQuery({ ...myContext });

  const forms = useMemo(() => forms2?.filter((f) => f.type === 'U'), [forms2]);

  useEffect(() => {
    if (data) {
      form.resetFields();
      form.setFieldsValue(data);
    } else {
      form.resetFields();
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => reset(), 3000);
    }
  }, [isSuccess]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <Form form={form} onFinish={(values) => update({ ...myContext, extended: values })}>
        <Form.Item label="Cacher la configuration de l'email" name="FO_INVIT_HIDE_CONFIG_EMAIL">
          <Switch />
        </Form.Item>
        <Form.Item label="Couleur primaire" name="FO_INVIT_PRIMARY_COLOR">
          <MyColorPicker />
        </Form.Item>
        <Form.Item label="Couleur de fond du site" name="backgroundColor">
          <MyColorPicker />
        </Form.Item>
        <Form.Item label="Import sans choisir les colonnes" name="FO_INVIT_IMPORT_V2">
          <Switch />
        </Form.Item>
        <Form.Item label="Afficher les liens en reporting" name="FO_INVIT_SHOW_USER_LINKS">
          <Switch />
        </Form.Item>
        <Form.Item label="Activer le report actif" name="FO_INVIT_REPORT_ACTIVE">
          <Switch />
        </Form.Item>
        <Form.Item label="Formulaire invitation unique" name="FO_INVIT_FORM_ID">
          <Select
            options={forms?.map((m) => ({ label: m.name, value: m.id }))}
          />
        </Form.Item>

        {isSuccess && (
          <Form.Item>
            <Alert message="La configuration a été enregistrée avec succès" type="success" showIcon />
          </Form.Item>
        )}

        <Form.Item>
          <Button style={{ width: '100%' }} type="primary" loading={isLoading} htmlType="submit">Enregistrer</Button>
        </Form.Item>
      </Form>
    </div>
  );
}
