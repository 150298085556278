import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space } from 'antd';
import React from 'react';
import useMe from '../../hooks/useMe';
import { useAuth } from '../../hooks/useAuth';
import useNav from '../../hooks/useNav';

export default function Profile() {
  const me = useMe();
  const { logout } = useAuth();
  const nav = useNav();

  const items = [
    {
      key: '0',
      label: 'Mon profil',
      onClick: ({ domEvent: e }) => nav(`/users/${me.id}`, { e }),
    },
    {
      type: 'divider',
    },
    {
      key: '3',
      label: <Button type="primary" danger>Se déconnecter</Button>,
      type: 'danger',
      onClick: () => logout(),
    },
  ];

  return (
    <Dropdown
      menu={{
        items,
      }}
      trigger={['click']}
    >
      <Button>
        <Space>
          {`${me.firstName || ''} ${me.lastName || ''}`}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
}
