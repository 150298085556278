import { EyeOutlined } from '@ant-design/icons';
import { Button, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useGetInvoicesQuery } from '../../../api/v2/invoices';
import { useMyContext } from '../../../hooks/useMyContext';
import ShowInvoice from '../../user/Tabs/invoices/ShowInvoice';
import ExportInvoices from '../Export';

export default function InvoicesCreditsPage() {
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });

  const { myContext } = useMyContext();

  const { data, isFetching } = useGetInvoicesQuery({ ...myContext, isCredit: true, ...pagination });

  useEffect(() => {
    if (data) {
      setPagination((p) => ({ ...p, total: data.total }));
    }
  }, [data]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <div style={{ fontSize: 22, fontWeight: 600 }}>Avoirs</div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 8 }}>
        <Tag color="processing">{`Nombre d'avoirs : ${data?.total || 0}`}</Tag>
        <ExportInvoices isCredit disabled={data?.invoices?.length <= 0} />
      </div>
      <Table
        rowKey={(record) => record.id}
        dataSource={data?.invoices}
        scroll={{ x: true }}
        columns={[
          { title: 'Référence', dataIndex: 'label' },
          { title: 'Date', dataIndex: ['bill', 'date'] },
          { title: 'Total HT', dataIndex: 'price', render: (p) => `${p} €` },
          { title: 'Total TTC', dataIndex: 'priceTva', render: (p) => `${p} €` },
          {
            render: (v) => (
              <ShowInvoice invoice={v}>
                <Button type="primary" icon={<EyeOutlined />} />
              </ShowInvoice>
            ),
          },
        ]}
        pagination={pagination}
        onChange={setPagination}
        loading={isFetching}
      />
    </div>
  );
}
