import { AutoComplete, Input } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useLazySearchQuery } from '../api/v2/search';
import { useMyContext } from '../hooks/useMyContext';

export default function SearchUsers({ onSelect, defaultSearch, onlyCompanies }) {
  const [search, setSearch] = useState('');

  const requestRef = useRef(null);
  const { myContext } = useMyContext();

  const [getSearch, { data, isFetching }] = useLazySearchQuery();

  useEffect(() => {
    if (requestRef.current) {
      requestRef.current.abort();
    }

    if (search) {
      requestRef.current = getSearch({ ...myContext, search, onlyUsers: !onlyCompanies, onlyCompanies });
    }
  }, [myContext, search]);

  useEffect(() => {
    if (defaultSearch) {
      setSearch(defaultSearch);
    }
  }, [defaultSearch]);

  return (
    <AutoComplete
      style={{ flexGrow: 1, maxWidth: '100%' }}
      options={data?.options}
      onSearch={setSearch}
      value={search}
      onSelect={(a, b) => {
        onSelect(a, b);
        setSearch('');
      }}
      onClear={() => {
        onSelect(null, null);
        setSearch('');
      }}
      autoFocus
      allowClear
    >
      <Input.Search loading={isFetching} placeholder="Chercher ..." />
    </AutoComplete>
  );
}
