import apiV1 from '.';

export const employeesApi = apiV1.injectEndpoints({
  endpoints: (builder) => ({
    getEmployees: builder.query({
      query: ({ domainId, userId }) => ({
        url: `/domains/${domainId}/users/${userId}/companies`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data,
    }),
    createEmployee: builder.mutation({
      query: ({ domainId, employee }) => ({
        url: `/v2/domains/${domainId}/employees`,
        method: 'POST',
        body: employee,
      }),
      invalidatesTags: ['EMPLOYEE'],
    }),
    removeEmployee: builder.mutation({
      query: ({ domainId, employeeId }) => ({
        url: `/v2/domains/${domainId}/employees/${employeeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['EMPLOYEE'],
    }),
    addEmployee: builder.mutation({
      query: ({ domainId, userId, companyId }) => ({
        url: `/domains/${domainId}/users/${userId}/companies/${companyId}`,
        method: 'POST',
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['EMPLOYEE'],
    }),
  }),
});

export const {
  useLazyGetEmployeesQuery,
  useCreateEmployeeMutation,
  useRemoveEmployeeMutation,
  useAddEmployeeMutation,
} = employeesApi;
