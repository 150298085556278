import { Alert, Button, Form, Select, Space } from 'antd';
import React, { useMemo, useState } from 'react';
import { useParticipations } from './useParticipations';
import useNav from '../../../../hooks/useNav';
import { useMyContext } from '../../../../hooks/useMyContext';
import { useGetAllEmployeesQuery, useGetUserQuery, useUpdateUserEventMutation } from '../../../../api/v1/users';
import { useGetVoucherByUserIdQuery } from '../../../../api/v1/vouchers';

function ParticipationsAddUsers() {
  const [uId, setUId] = useState();

  const nav = useNav();
  const { myContext } = useMyContext();
  const { setStep, setUserId, myUser, myUserId } = useParticipations();
  const [form] = Form.useForm();

  const { data } = useGetUserQuery({ ...myContext, userId: uId });
  const registeredBy = useMemo(() => data?.UserEvent?.registeredBy?.split('(')[1].split(')')[0], [
    data,
  ]);
  const { data: voucher } = useGetVoucherByUserIdQuery({ ...myContext, userId: registeredBy });
  const [updateUserEvent] = useUpdateUserEventMutation();
  const { data: data2 } = useGetAllEmployeesQuery({ ...myContext, userId: myUserId });

  return (
    <Form
      form={form}
      onFinish={async () => {
        await updateUserEvent({
          ...myContext,
          userId: uId,
          userEvent: {
            registeredBy: `${myUser.firstName || ''} ${myUser.lastName || ''} (${myUser.id})`,
            registeredBy2: myUser.id,
          },
        }).unwrap();
        setStep(2);
        setUserId(uId);
      }}
      layout="vertical"
    >
      <Form.Item>
        <Select
          options={data2?.users?.map((u) => ({
            label: `${u?.firstName || ''} ${u?.lastName || ''} ${u?.email ? `(${u.email})` : ''}`,
            value: u.id,
          }))}
          value={uId}
          onChange={setUId}
        />
      </Form.Item>
      {data?.UserEvent?.registeredBy && (
        <Form.Item>
          <Alert
            type="warning"
            message="Cet utilisateur a déjà été inscrit par une autre personne"
          />
        </Form.Item>
      )}
      {data?.UserEvent?.registeredBy && voucher && (
        <Form.Item>
          <Alert
            type="error"
            message={(
              <Space>
                <div>Cet utilisateur est déjà dans un voucher</div>
                <Button
                  type="primary"
                  onClick={() => nav(`/vouchers/${voucher.code}`, { newTab: true })}
                >
                  Ouvrir
                </Button>
              </Space>
            )}
          />
        </Form.Item>
      )}
      <Form.Item>
        <Alert
          type="info"
          message="Si la liste (ci-dessus) ne contient pas la personne que vous souhaitez inscrire, veuillez cliquer sur « CREER UN
        TESTEUR »"
        />
      </Form.Item>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          type="primary"
          onClick={() => {
            setStep(1);
          }}
        >
          CREER UN TESTEUR
        </Button>
        <Form.Item shouldUpdate>
          <Button type="primary" htmlType="submit" disabled={!uId || voucher}>
            Suivant
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}

export default ParticipationsAddUsers;
