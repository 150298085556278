import apiV2 from '.';

export const employeesApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    getEmployees: builder.query({
      query: ({ domainId, userId }) => ({
        url: `/domains/${domainId}/users/${userId}/companies`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data?.companies,
      providesTags: ['EMPLOYEES', 'COMPANIES'],
    }),
    getEmployeesByCompany: builder.query({
      query: ({ domainId, eventId, companyId }) => ({
        url: eventId
          ? `/domains/${domainId}/events/${eventId}/companies/${companyId}/employees`
          : `/domains/${domainId}/companies/${companyId}/employees`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data?.users,
      providesTags: ['EMPLOYEES', 'COMPANIES', 'USERS'],
    }),
    removeEmployee: builder.mutation({
      query: ({ domainId, companyId, userId }) => ({
        url: `/domains/${domainId}/companies/${companyId}/users/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['EMPLOYEES'],
    }),
    createEmployee: builder.mutation({
      query: ({ domainId, companyId, userId }) => ({
        url: `/domains/${domainId}/companies/${companyId}/users/${userId}`,
        method: 'POST',
      }),
      invalidatesTags: ['EMPLOYEES'],
    }),
  }),
});

export const { useGetEmployeesQuery, useGetEmployeesByCompanyQuery, useRemoveEmployeeMutation, useCreateEmployeeMutation } = employeesApi;
