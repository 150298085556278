import { Button, Table } from 'antd';
import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useGetModelsQuery, useRemoveModelMutation } from '../../../api/v2/models';
import { useMyContext } from '../../../hooks/useMyContext';
import ModelsEdition from './Edition';
import ModelsRealEdition from './EditionV2';
import ButtonConfirm from '../../../components/ButtonConfirm';

export default function ModelsPage() {
  const { myContext, event } = useMyContext();

  const { data: models } = useGetModelsQuery(myContext);
  const [remove] = useRemoveModelMutation();

  const expandedRowRender = (parent) => {
    const columns = [
      { title: 'Label', dataIndex: 'value' },
      { title: 'Key', dataIndex: 'key' },
      { title: 'Quota', dataIndex: 'quota' },
      { title: 'Nombre de participants', dataIndex: 'totalParticipation' },
    ];

    return <Table rowKey="id" size="small" columns={columns} dataSource={parent.values} pagination={false} />;
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <ModelsRealEdition>
        <Button type="primary">Ajouter une nouvelle donnée étendue</Button>
      </ModelsRealEdition>
      <Table
        rowKey="id"
        expandable={{
          // showExpandColumn: false,
          expandedRowRender,
          rowExpandable: (parent) => parent.values,
          // expandedRowKeys: models?.map((m) => m.id),
        }}
        scroll={{ x: true }}
        dataSource={models?.filter((f) => f.isExtended)?.filter((f) => (event ? f.eventId === event.id : true))}
        columns={[
          { title: 'Label', dataIndex: 'value' },
          { title: 'Short label', dataIndex: 'labelShort' },
          { title: 'Key', dataIndex: 'key' },
          {
            title: 'Cible',
            render: (v) => {
              if (v.model === 'U') {
                return 'Utilisateur';
              }
              if (v.model === 'C') {
                return 'Société';
              }
              if (v.model === 'UE') {
                return 'Utilisateur (événementiel)';
              }
              return '';
            },
          },
          {
            title: 'Utilisée',
            dataIndex: 'isUsed',
            render: (v) => (v ? (
              <CheckCircleOutlined style={{ color: 'green' }} />
            ) : (
              <CloseCircleOutlined style={{ color: 'red' }} />
            )),
          },
          {
            title: 'Quota',
            dataIndex: 'hasQuota',
            render: (v) => (v ? (
              <CheckCircleOutlined style={{ color: 'green' }} />
            ) : (
              <CloseCircleOutlined style={{ color: 'red' }} />
            )),
          },
          {
            render: (v) => (
              <div style={{ display: 'flex', gap: 8 }}>
                <ModelsRealEdition data={v}>
                  <Button type="primary">Modifier</Button>
                </ModelsRealEdition>
                <ButtonConfirm
                  title="Voulez-vous vraiment supprimer cette donnée étendue ?"
                  onOk={() => remove({ ...myContext, id: v.id })}
                >
                  <Button type="primary" danger>Supprimer</Button>
                </ButtonConfirm>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
