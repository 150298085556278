import { Form, Input } from 'antd';
import React from 'react';

export default function MeetingFacturation() {
  return (
    <Form.Item label="Préfixe du numéro de facture GAYAKOA" name="CONF_EXPO_INVOICE_PREFIX">
      <Input placeholder="2024/***123" />
    </Form.Item>
  );
}
