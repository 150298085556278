import queryString from 'query-string';
import apiV1 from '.';

export const badgesApi = apiV1.injectEndpoints({
  endpoints: (builder) => ({
    createBadge: builder.mutation({
      query: ({ domainId, eventId, badge }) => ({
        url: eventId
          ? `/domains/${domainId}/events/${eventId}/badges`
          : `/domains/${domainId}/badges`,
        method: 'POST',
        body: badge,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ['Badges'],
    }),
    downloadBadge: builder.mutation({
      query: ({ domainId, eventId, userId, badgeId, usersId, codes }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/badges/download?${queryString.stringify({
          userId,
          badgeId,
        })}`,
        method: 'POST',
        body: { usersId, codes },
      }),
      transformResponse: (response) => response.data,
    }),
    getDownloadBadge: builder.query({
      query: ({ domainId, eventId, userId, badgeId, badge }) => ({
        url: `/v2/domains/${domainId}/events/${eventId}/badges/download?${queryString.stringify({
          userId,
          badgeId,
        })}`,
        method: 'POST',
        body: { badge },
      }),
      transformResponse: (response) => response.data,
      providesTags: ['Badges'],
    }),
    getBadges: builder.query({
      query: ({ domainId, eventId }) => ({
        url: `/domains/${domainId}/events/${eventId}/badges`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data,
      providesTags: ['Badges'],
    }),
  }),
});

export const {
  useCreateBadgeMutation,
  useDownloadBadgeMutation,
  useGetDownloadBadgeQuery,
  useGetBadgesQuery,
} = badgesApi;
