import queryString from 'query-string';
import apiV2 from '.';

export const companiesApi = apiV2.injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.query({
      query: ({ domainId, page, pageSize, filters }) => ({
        url: `/domains/${domainId}/companies/get?${queryString.stringify({ page, pageSize })}`,
        method: 'POST',
        body: { filters },
      }),
      transformResponse: (response) => response.data,
      providesTags: ['COMPANIES', 'EMPLOYEES'],
    }),
    getCompany: builder.query({
      query: ({ domainId, companyId }) => ({
        url: `/domains/${domainId}/companies/${companyId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.company,
      providesTags: ['COMPANIES', 'EMPLOYEES'],
    }),
    getCoworkers: builder.query({
      query: ({ domainId, eventId, userId }) => ({
        url: eventId
          ? `/domains/${domainId}/events/${eventId}/users/${userId}/coworkers`
          : `/domains/${domainId}/users/${userId}/coworkers`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.coworkers,
      providesTags: ['COMPANIES', 'USERS', 'PARTICIPATION', 'EMPLOYEES'],
    }),
    updateCompany: builder.mutation({
      query: ({ domainId, companyId, company }) => ({
        url: `/domains/${domainId}/companies/${companyId}`,
        method: 'PUT',
        body: { company },
      }),
      invalidatesTags: ['COMPANIES'],
    }),
  }),
});

export const { useGetCompaniesQuery, useGetCompanyQuery, useGetCoworkersQuery, useUpdateCompanyMutation } = companiesApi;
