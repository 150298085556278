import qs from 'query-string';
import apiV1 from '.';

export const companionsApi = apiV1.injectEndpoints({
  endpoints: (builder) => ({
    getCompanions: builder.query({
      query: ({ domainId, userId, eventId }) => `/domains/${domainId}/events/${eventId}/companions?userId=${userId}`,
      transformResponse: (response) => response.data?.users || [],
      providesTags: ['COMPANION'],
    }),
    addCompanion: builder.mutation({
      query: ({ domainId, eventId, user, userId }) => ({
        url: `/domains/${domainId}/events/${eventId}/companions?${qs.stringify({ userId })}`,
        method: 'POST',
        body: { users: [user] },
      }),
      transformResponse: (response) => response.data?.users || [],
      invalidatesTags: ['Companion'],
    }),
  }),
});

export const { useGetCompanionsQuery, useAddCompanionMutation } = companionsApi;
