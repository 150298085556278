import { useMemo } from 'react';
import { useMyContext } from './useMyContext';

const useSettingsBo = () => {
  const { domain, event } = useMyContext();

  const extended = useMemo(() => (domain?.extended || {}), [domain]);

  const settings = useMemo(() => ({
    ...extended,
    primaryColor: (event && extended[`EVENT_${event.id}_COLOR`]) || extended.SIDEBAR_ITEM_SELECTED_BACKGROUND_COLOR_V2 || '#1677ff',
    navbarColor: (event && extended[`EVENT_${event.id}_COLOR`]) || extended.SIDEBAR_BACKGROUND_COLOR_V2 || '#001529',
    isMultiLanguage: event && extended[`EVENT_${event.id}_IS_MULTI_LANGUAGE`],
    hasFreeArticles: event && extended[`EVENT_${event.id}_HAS_FREE_ARTICLES`],
    withoutTva: event && extended[`EVENT_${event.id}_WITHOUT_TVA`],
    facturationGayakoa: event && extended[`EVENT_${event.id}_FACTURATION_GAYAKOA`],
    facturationOthers: event && extended[`EVENT_${event.id}_FACTURATION_OTHERS`],
    companions: event && extended[`EVENT_${event.id}_COMPANIONS`],
    shooting: event && extended[`EVENT_${event.id}_SHOOTING`],
    foPay: event && extended[`EVENT_${event.id}_FO_PAY`],
    SHOW_USER_SHOP: event && extended[`EVENT_${event.id}_SHOW_USER_SHOP`],
    scanWelcomeTemplate: event && extended[`EVENT_${event.id}_SCAN_WELCOME_TEMPLATE`],
    SHOW_USER_MODERATION: extended.SHOW_USER_MODERATION,
    SHOW_USER_INVITATIONS: event && extended[`EVENT_${event.id}_SHOW_USER_INVITATIONS`],
    SHOW_USER_LINKS: event && extended[`EVENT_${event.id}_SHOW_USER_LINKS`],
  }), [event, domain]);

  return settings;
};

export default useSettingsBo;
