import { Table } from 'antd';
import { format } from 'date-fns';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetParticipationsQuery } from '../../../../api/v2/participations';
import { useMyContext } from '../../../../hooks/useMyContext';
import BooleanIcon from '../../../../components/BooleanIcon';

export default function UserParticipations() {
  const { myContext } = useMyContext();
  const { userId } = useParams();

  const { data: participations, isFetching } = useGetParticipationsQuery({ ...myContext, userId });

  return (
    <div>
      <Table
        scroll={{ x: true }}
        loading={isFetching}
        dataSource={participations}
        columns={[
          { title: 'Événement', dataIndex: 'name' },
          {
            title: 'Date',
            dataIndex: 'start',
            render: (v) => `${format(new Date(v), 'dd/MM/yyyy')}`,
          },
          { title: 'Inscrit', dataIndex: 'isRegistered', render: (v) => <BooleanIcon value={v} /> },
          { title: 'Présent', dataIndex: 'isPresent', render: (v) => <BooleanIcon value={v} /> },
        ]}
      />
    </div>
  );
}
