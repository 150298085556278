import {
  Button,
  Form, Input,
  InputNumber,
  Modal,
  Radio,
  Switch,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useCreateArticleMutation, useUpdateArticleMutation } from '../../api/v2/articles';
import { useMyContext } from '../../hooks/useMyContext';

export default function ArticleEdition({ values }) {
  const [open, setOpen] = useState(false);

  const { myContext, event } = useMyContext();
  const [form] = Form.useForm();

  const [create] = useCreateArticleMutation();
  const [update] = useUpdateArticleMutation();

  const isCreation = !values?.id;

  const close = () => {
    form.resetFields();
    setOpen(false);
  };

  useEffect(() => {
    form.setFieldsValue({ tva: 20, ...(values || {}) });
  }, [values]);

  console.log(event.days);

  return (
    <>
      <Button type="primary" onClick={() => setOpen(true)}>
        {isCreation ? 'Ajouter un article' : 'Modifier'}
      </Button>
      <Modal title={isCreation ? 'Ajouter un article' : 'Modifier un article'} open={open} onCancel={close} footer={null}>
        <Form
          form={form}
          onValuesChange={(property, v) => {
            if (property.price) {
              const priceTTC = Number((v.price * (1 + ((v.tva || 0) / 100))).toFixed(2));
              form.setFieldsValue({ priceTTC });
            } else if (property.priceTTC) {
              const price = Number((v.priceTTC / (1 + ((v.tva || 0) / 100))).toFixed(2));
              form.setFieldsValue({ price });
            } else if (property.tva && v.price && v.priceTTC) {
              form.setFieldsValue({ price: 0, priceTTC: 0 });
            }
          }}
          onFinish={async (article) => {
            const articleFinal = { ...article };

            if (isCreation) {
              await create({ ...myContext, article: articleFinal }).unwrap();
            } else {
              await update({ ...myContext, article: articleFinal, articleId: values.id }).unwrap();
            }

            close();
          }}
        >
          <Form.Item label="Label" name="label" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Label en Anglais" name="labelEN">
            <Input />
          </Form.Item>
          <Form.Item label="Label facture" name="label2">
            <Input />
          </Form.Item>
          <Form.Item label="Code unique" name="code">
            <Input />
          </Form.Item>
          <Form.Item label="TVA" name="tva" rules={[{ required: true }]}>
            <Radio.Group
              options={[
                { label: '20%', value: 20 },
                { label: '10%', value: 10 },
                { label: '5,5%', value: 5.5 },
                { label: '2,1%', value: 2.1 },
                { label: '0%', value: 0 },
              ]}
              optionType="button"
              buttonStyle="solid"
              block
            />
          </Form.Item>
          <Form.Item label="Prix HT" name="price" rules={[{ required: true }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item label="Prix TTC" name="priceTTC" rules={[{ required: true }]} shouldUpdate>
            <InputNumber />
          </Form.Item>
          {[{ id: 0, label: 'Événement' }, ...event.days].map(({ id, day, label }) => (
            <>
              <div style={{ fontSize: 20, fontWeight: 700, textTransform: 'capitalize' }}>{label || format(day, 'EEEE dd MMMM')}</div>
              <div style={{ display: 'flex', gap: 16 }}>
                <Form.Item
                  label="Visible"
                  name={['days', id.toString(), 'isShow']}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
                <Form.Item
                  label="Pré-sélectionné"
                  name={['days', id.toString(), 'isSelected']}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
                <Form.Item
                  label="Non modifiable"
                  name={['days', id.toString(), 'isDisabled']}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </div>
            </>
          ))}
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button type="primary" htmlType="submit">
              {isCreation ? 'Créer' : 'Modifier'}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
