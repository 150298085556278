import { Button, Checkbox, Radio, Space, Spin } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useParticipations } from './useParticipations';
import { useMyContext } from '../../../../hooks/useMyContext';
import { useAddArticleUserMutation, useRemoveArticleUserMutation } from '../../../../api/v1/articles.api';
import {
  useGetUserQuery,
  useRemoveEventDayUserMutation,
  useUpdateUserEventDaysMutation,
  useUpdateUserEventMutation,
} from '../../../../api/v1/users';
import { useGetParticipationsByDayAndUserQuery } from '../../../../api/v1';

function ParticipationsArticles() {
  const { myContext } = useMyContext();
  const { userId, onClose, myUser } = useParticipations();

  const [addArticleUser] = useAddArticleUserMutation();
  const [removeArticleUser] = useRemoveArticleUserMutation();

  const { data: participations, isLoading: isLoading2 } = useGetParticipationsByDayAndUserQuery({
    ...myContext,
    userId,
  });
  const [updateDay] = useUpdateUserEventDaysMutation();
  const { data: user } = useGetUserQuery({ ...myContext, userId });
  const [updateUserEvent] = useUpdateUserEventMutation();
  const [removeDay] = useRemoveEventDayUserMutation();

  if (isLoading2) {
    return <Spin />;
  }

  return (
    <div>
      <div style={{ fontWeight: 600 }}>
        {user?.firstName}
        {' '}
        {user?.lastName}
      </div>
      <div style={{ fontWeight: 500, marginBottom: 20 }}>{user?.Authentication?.email}</div>
      {participations?.map(({ dayId, day, articles, present, registered, isDelivered }) => (
        <div key={`${userId}-${dayId}`} style={{ marginBottom: 20 }}>
          <Space style={{ marginBottom: 10 }}>
            <div style={{ textTransform: 'capitalize' }}>
              {dayjs(day).format('dddd DD MMMM YYYY ')}
            </div>
            {registered || present ? (
              <Button
                type="primary"
                onClick={async () => {
                  const lastParticipation = participations.filter((f) => f.registered || f.present).length === 1;
                  if (lastParticipation) {
                    updateUserEvent({
                      ...myContext,
                      userId,
                      eventUser: { registeredBy: null, registeredBy2: null },
                    });
                  }
                  removeDay({ ...myContext, userId, dayId });
                }}
                disabled={isDelivered}
                danger
              >
                Supprimer
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={async () => {
                  if (dayjs(day).isSame(new Date(), 'day')) {
                    updateDay({
                      ...myContext,
                      userId,
                      dayId,
                      userEventDay: { present: true, presentAt: new Date() },
                    });
                  } else {
                    updateDay({
                      ...myContext,
                      userId,
                      dayId,
                      userEventDay: { registered: true, registeredAt: new Date() },
                    });
                  }

                  await updateUserEvent({
                    ...myContext,
                    userId,
                    eventUser: {
                      registeredBy: `${myUser.firstName || ''} ${myUser.lastName || ''} (${
                        myUser.id
                      })`,
                      registeredBy2: myUser.id,
                    },
                  }).unwrap();
                }}
              >
                Ajouter
              </Button>
            )}
          </Space>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {(registered || present)
              && articles?.map((a) => {
                const d = a.days?.[0];

                if (d?.isRadio) {
                  return (
                    <Radio
                      disabled={a.isDelivered}
                      checked={!!a.userArticle}
                      onClick={async () => {
                        if (a.userArticle) {
                          removeArticleUser({ ...myContext, userId, dayId, articleId: a.id });
                        } else {
                          addArticleUser({ ...myContext, userId, dayId, articleId: a.id });
                        }
                      }}
                      style={{ marginBottom: 8, marginLeft: 0 }}
                    >
                      {`${a.label} (${a.price}€ H.T.)`}
                    </Radio>
                  );
                }

                return (
                  <Checkbox
                    disabled={a.isDelivered}
                    checked={!!a.userArticle}
                    onClick={async () => {
                      if (a.userArticle) {
                        removeArticleUser({ ...myContext, userId, dayId, articleId: a.id });
                      } else {
                        addArticleUser({ ...myContext, userId, dayId, articleId: a.id });
                      }
                    }}
                    style={{ marginBottom: 8, marginLeft: 0 }}
                  >
                    {`${a.label} (${a.price}€ H.T.)`}
                  </Checkbox>
                );
              })}
            {/* {(registered || present) &&
              articles?.map(a => (
                <Checkbox
                  disabled={a.userArticle?.isDelivered}
                  checked={!!a.userArticle}
                  onClick={async () => {
                    if (a.userArticle) {
                      removeArticleUser({ ...myContext, userId, dayId, articleId: a.id })
                    } else {
                      addArticleUser({ ...myContext, userId, dayId, articleId: a.id })
                    }
                  }}
                  style={{ marginBottom: 8, marginLeft: 0 }}
                >
                  {`${a.label} (${a.price}€ H.T.)`}
                </Checkbox>
              ))} */}
          </div>
        </div>
      ))}

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="primary" onClick={onClose}>
          Terminer
        </Button>
      </div>
    </div>
  );
}

export default ParticipationsArticles;
