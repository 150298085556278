import { Button, Divider, Input, Space, Table } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useMyContext } from '../../../hooks/useMyContext';
import useNav from '../../../hooks/useNav';
import useSelectUser from './components/useSelectUser';
import { useGetVouchersV2Query } from '../../../api/v1/vouchers';
import QReader from '../../../components/QReader';
import { useUpdateParticipationMutation } from '../../../api/v2/participations';

const searchUser = (user, search) => (
  user?.lastName?.toLowerCase().includes(search.toLowerCase())
    || user?.firstName?.toLowerCase().includes(search.toLowerCase())
    || user?.email?.toLowerCase().includes(search.toLowerCase())
    || user?.companions?.toLowerCase().includes(search.toLowerCase())
);

function VouchersScreen() {
  const [mode, setMode] = useState();
  const [search, setSearch] = useState(localStorage.getItem('search') || '');

  const { myContext } = useMyContext();
  const nav = useNav();
  const { user, SelectUser } = useSelectUser({ placeholder: 'Chercher parmis tous les utilisateurs' });

  const { data, isLoading } = useGetVouchersV2Query(myContext);
  const [update, { data: participation }] = useUpdateParticipationMutation();

  const vouchers = useMemo(() => (search ? data?.filter((f) => searchUser(f, search)) : data), [
    data,
    search,
  ]);

  useEffect(() => {
    if (search !== undefined) {
      localStorage.setItem('search', search);
    }
  }, [search]);

  useEffect(() => {
    if (participation) {
      nav(`/vouchers/${participation.voucherCode}`);
    }
  }, [participation]);

  useEffect(() => {
    if (user) {
      if (user.participation?.voucherCode) {
        nav(`/vouchers/${user.participation.voucherCode}`);
      } else {
        update({ ...myContext, userId: user.id, participation: { } });
      }
    }
  }, [user]);

  return (
    <div>
      {mode === 'scan' && (
        <div>
          <Button style={{ marginBottom: 10 }} onClick={() => setMode(null)}>
            Retour
          </Button>
          <QReader
            onChange={async (code) => {
              if (code) {
                nav(`/vouchers/${code}`);
              }
            }}
          />
        </div>
      )}
      {!mode && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <div style={{ display: 'flex', gap: 8 }}>
            <Button type="primary" onClick={() => setMode('scan')}>
              Scanner un voucher
            </Button>
            <Button type="primary" onClick={(e) => nav('/vouchers/create', { e })}>
              Créer un voucher
            </Button>
          </div>
          {SelectUser}
          <Divider style={{ margin: '8px 0px' }} />
          <Input
            placeholder="Chercher dans le tableau ci-dessous"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Table
            size="small"
            dataSource={vouchers}
            loading={isLoading}
            columns={[
              { title: 'Prénom', dataIndex: ['firstName'] },
              { title: 'Nom', dataIndex: ['lastName'] },
              { title: 'Email', dataIndex: ['email'] },
              {
                title: 'Société',
                render: (c) => c && `${c.name} ${c.postalCode} ${c.city}`,
              },
              {
                render: (v) => (
                  <Button
                    size="small"
                    type="primary"
                    onClick={(e) => nav(`/vouchers/${v.voucherCode}`, { e })}
                  >
                    Voir
                  </Button>
                ),
              },
            ]}
          />
        </div>
      )}
    </div>
  );
}

export default VouchersScreen;
