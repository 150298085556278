import { Button, Table } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetMyInvitationsQuery } from '../../../../api/v2/users';
import BooleanIcon from '../../../../components/BooleanIcon';
import { useMyContext } from '../../../../hooks/useMyContext';
import useNav from '../../../../hooks/useNav';

export default function UserInvitations() {
  const { myContext, event } = useMyContext();
  const { userId } = useParams();
  const nav = useNav();

  const { data: invitations, isFetching } = useGetMyInvitationsQuery({ ...myContext, userId });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
      <Table
        scroll={{ x: true }}
        loading={isFetching}
        dataSource={invitations}
        columns={[
          { title: 'Prénom', dataIndex: 'firstName', sorter: (a, b) => a.firstName?.localeCompare(b.firstName) },
          { title: 'Nom', dataIndex: 'lastName', sorter: (a, b) => a.lastName?.localeCompare(b.lastName) },
          { title: 'Email', dataIndex: 'email', sorter: (a, b) => a.email?.localeCompare(b.email) },
          { title: 'Mobile', dataIndex: 'mobile' },
          ...(event ? [
            {
              title: 'Invité',
              dataIndex: 'invited',
              render: (v) => <BooleanIcon value={v} />,
              sorter: (a, b) => b.invited - a.invited,
            },
            {
              title: 'Inscrit',
              dataIndex: 'isRegistered',
              render: (v) => <BooleanIcon value={v} />,
              sorter: (a, b) => b.isRegistered - a.isRegistered,
              defaultSortOrder: 'ascend',
            },
            {
              title: 'Présent',
              dataIndex: 'isPresent',
              render: (v) => <BooleanIcon value={v} />,
              sorter: (a, b) => b.isPresent - a.isPresent,
            },
          ] : []),
          {
            render: (v) => (
              <div style={{ display: 'flex', gap: 8 }}>
                <Button type="primary" onClick={(e) => nav(`/users/${v.id}`, { e })}>Voir son profil</Button>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
}
