import React, { useMemo } from 'react';
import { Button } from 'antd';
import { useParams } from 'react-router-dom';
import { useMyContext } from '../../../hooks/useMyContext';
import { useGetTyposQuery } from '../../../api/v2/typo';
import { useUpdateParticipationMutation } from '../../../api/v2/participations';
import { useGetEmployeesQuery } from '../../../api/v2/employees';

export default function UserEventActivation() {
  const { myContext } = useMyContext();
  const { userId } = useParams();

  const { data: typos } = useGetTyposQuery(myContext);
  const [update, { isLoading }] = useUpdateParticipationMutation();
  const { data: employees } = useGetEmployeesQuery({ ...myContext, userId });

  const employeeId = useMemo(() => employees?.[0]?.employeeId || null, [employees]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 32, textAlign: 'center' }}>
      <div>{'Cette personne n\'est pas encore activée pour cet événement.'}</div>
      {typos && typos.length > 1 && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
          <div>Activer en tant que :</div>
          {typos?.map((typo) => (
            <Button
              type="primary"
              onClick={() => update({ ...myContext, userId, participation: { typoId: typo.id, employeeId } })}
              loading={isLoading}
            >
              {typo.label}
            </Button>
          ))}
        </div>
      )}
      {typos && typos.length === 1 && (
        <Button type="primary" onClick={() => update({ ...myContext, userId, participation: { employeeId } })} loading={isLoading}>
          ACTIVER CETTE PERSONNE SUR L’ÉVÉNEMENT
        </Button>
      )}
    </div>
  );
}
